<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="文旅融合" path='/cultural' child="文创产品" childPath='/industriesInfo'></myBread>

      <div class="_top">
        <div class="_tabs1">
          <ul>
            <li>文创产品</li>
          </ul>
        </div>
      </div>

      <div class="_wcList">
        <div
          class="_li"
          v-for="(item, index) in list"
          :key="index"
          @click="goDetail(item)"
          style="cursor: pointer;"
        >
          <div class="_img">
            <img :src="$IMG_URL + item.cover" alt="" />
          </div>
          <p>{{ item.title }}</p>
        </div>
      </div>

      <div class="_page">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.pagesize"
          @pagination="getList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getArticle, addHit } from "@/api/getData";
export default {
  data() {
    return {
      list: [],
      total: 0,
      queryParams: {
        cate_id: "19",
        page: 1,
        pagesize: 16,
      },
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `文创产品_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  async mounted() {
    await this.getList();
    

  },
  methods: {
    async getList() {
      await getArticle(this.queryParams).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
    goDetail(item) {
      const params = {
        id: item.id,
      };
      addHit(params);
      let url = location.origin;
      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/industriesDetail?id=${item.id}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
._page {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

._top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  border-top: 1px solid #a88b61;
  margin: 0 20px;

  ._tabs1 {
    ul {
      display: flex;

      li {
        color: #333;
        font-size: 22px;
        padding: 14px 30px;
        cursor: pointer;
        background-color: #a88b61;
        color: white;
      }
    }
  }

  ._more {
    margin-right: 20px;
  }
}

._wcList {
  margin: 0 20px;
  margin-top: 30px;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 51px;

  ._li {
    margin-right: 20px;
    width: 295px;
    margin-bottom: 20px;

    ._img {
      width: 295px;
      height: 188px;
      overflow: hidden;
border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.5s;
      }

      img:hover {
        transform: scale(1.1);
      }
    }

    p {
      background: #f9f9f9;
      width: 100%;
      padding: 10px 0;
      text-align: center;
      font-size: 20px;
      color: #333;
    }
  }

  ._li:nth-child(4n){
    margin-right: 0;
  }
}
</style>