<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="石窟专委会" path="/committee" child="石窟寺研究" childPath='/sksInfo'></myBread>

      <div class="_top">
        <div class="_tabs1">
          <ul>
            <li>《石窟寺研究》</li>
          </ul>
        </div>
        <div class="_more" @click="zgqs">征稿启事</div>
      </div>

      <div class="_list">
        <div
          class="_li"
          v-for="(item, index) in list"
          :key="index"
          @click="lookPdf(item)"
        >
          <div class="_img">
            <img :src="$IMG_URL + item.cover" alt="" />
          </div>
          <p>{{ item.title }}</p>
        </div>
      </div>

      <div class="_page">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.pagesize"
          @pagination="getList"
        />
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle, getDetail,addHit } from "@/api/getData";
export default {
  data() {
    return {
      list: [],
      total: 0,
      queryParams: {
        cate_id: "48",
        page: 1,
        pagesize: 10,
      },
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `石窟寺研究_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
 async mounted() {
    await this.getList();

 
  },
  methods: {
    zgqs() {
      let url = location.origin;
      window.open(`${url}/#/solictiDetail`);
    },
    async getList() {
      await getArticle(this.queryParams).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
    lookPdf(item) {
      const params = {
        id: item.id,
      };
      addHit(params)
      getDetail(params).then((res) => {
        window.open(this.$IMG_URL + res.data.pdf);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
._page {
  text-align: center;
  margin-bottom: 10px;
  margin-top: -20px;
}

._more {
  border: 1px solid #bb945b;
  color: #303030;
  font-size: 14px;
  padding: 8px 14px;
  cursor: pointer;
}

._top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  border-top: 1px solid #a88b61;
  margin: 0 20px;

  ._tabs1 {
    ul {
      display: flex;

      li {
        color: #333;
        font-size: 22px;
        padding: 14px 30px;
        cursor: pointer;
        background-color: #a88b61;
        color: white;
      }
    }
  }

  ._more {
    margin-right: 20px;
  }
}

._list {
  margin: 0 20px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  ._li {
    margin-right: 23px;
    margin-bottom: 30px;
    cursor: pointer;
    ._img {
      width: 228px;
      height: 297px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.5s;
      }

      img:hover {
        transform: scale(1.1);
      }
    }

    p {
      background: #f6f6f6;
      width: 100%;
      margin-top: 14px;
      border: 1px solid #dfdfdf;
      color: #666;
      font-size: 18px;
      text-align: center;
      padding: 12px 0;
    }
  }

  ._li:nth-child(5n) {
    margin-right: 0;
  }
}
</style>