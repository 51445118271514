<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="网站地图" path='/webMap'></myBread>

      <div class="_h4">
        <p>网站地图</p>
      </div>

      <div class="_div">
        <div class="_li" v-for="(item, index) in listMenu" :key="index">
          <div class="_p">
            <span @click="goRoute(item)">{{ item.title }}</span>
            <div class="_child">
              <span
                v-for="(v, i) in item.child"
                :key="i"
                @click="goChild(item, v)"
              >
                {{ v.subtit }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { listMenu } from "../../components/navMenu/list";
export default {
  data() {
    return {
      listMenu,
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `网站地图_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  methods: {
    goRoute(item) {
      console.log(item);
      this.$router.push({
        path: item.id,
      });
    },
    goChild(item, v) {
      if (v.subId) {
        this.$router.push({
          path: v.subId,
        });
      } else {
        this.$router.push({
          path: item.id,
          query: {
            tit: v.subtit,
          },
        });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
._h4 {
  height: 60px;
  background-color: #f9f9f9;
  border-top: 1px solid #a88b61;
  margin: 0 20px;

  p {
    width: 148px;
    height: 100%;
    font-size: 22px;
    color: white;
    background: #a88b61;
    text-align: center;
    line-height: 60px;
  }
}

._div {
  margin: 0 20px;
  margin-bottom: 80px;
  ._li {
    border-bottom: 1px solid #eee;
    padding: 24px 40px;
    ._p {
      > span {
        font-size: 20px;
        color: #333;
        cursor: pointer;
      }

      ._child {
        color: #666666;
        margin-top: 24px;
        margin-left: 60px;

        span {
          cursor: pointer;
          margin-right: 30px;
        }
      }
    }
  }
}
</style>