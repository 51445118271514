<template>
  <div class="_wrap">
    <div class="_auto">
      <!-- <myBread name="陈列外展" path='/exhibitInfo' :child="detail.title" childPath='/exhibitDetail'></myBread> -->
      <myBread
        name="互动交流"
        path="/exchange"
        child="陈列外展"
        childPath="/exhibitInfo"
        :tChild="detail.title"
        tChildPath="/exhibitDetail"
      ></myBread>

      <div class="_content">
        <h4>{{ detail.title }}</h4>
        <img class="_img" :src="$IMG_URL + detail.cover" alt="" />

        <div class="_lk">
          <div class="_l">
            <div>
              <span class="_sp1">主办单位：</span>
              <span class="_sp2">{{ detail.exhibition_unit }}</span>
            </div>
            <div>
              <span class="_sp1">展览地点：</span>
              <span class="_sp2">{{ detail.exhibition_addr }}</span>
            </div>
            <div>
              <span class="_sp1">展览日期：</span>
              <span class="_sp2">{{ detail.exhibition_date }}</span>
            </div>
          </div>
          <div
            class="_r"
            @click="look"
            v-if="detail.link != '' && detail.link != null"
          >
            在线看展
          </div>
        </div>

        <div class="_html" ref="myHtml" v-html="detail.content"></div>
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getDetail } from "@/api/getData";
export default {
  data() {
    return {
      id: "",
      detail: {},
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: `${this.detail.title}_龙门石窟研究院_官网`,
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  async mounted() {
    this.id = this.$route.query.id;
    await this.getDetail();
    setTimeout(() => {
      this.$scallHtmlImg();
    }, 50);

  },
  methods: {
    look() {
      window.open(this.detail.link);

      // if(this.detail.link!='' && this.detail.link!=null){
      //     window.open(this.detail.link)

      // }else{
      //     this.$message.warning("")
      // }
    },
    async getDetail() {
      const params = {
        id: this.id,
      };
      await getDetail(params).then((res) => {
        this.detail = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
._content {
  padding: 0 60px;
  padding-bottom: 80px;

  h4 {
    text-align: center;
    font-size: 26px;
    color: #333;
  }

  ._img {
    width: 920px;
    height: 518px;
    object-fit: cover;
    display: block;
    margin: 40px auto;
  }

  ._lk {
    width: 100%;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 28px;

    ._l {
      > div {
        padding: 5px 0;

        ._sp1 {
          color: #333;
          font-weight: bold;
        }

        ._sp2 {
          color: #333;
        }
      }
    }

    ._r {
      width: 160px;
      height: 70px;
      color: white;
      font-size: 22px;
      line-height: 70px;
      text-align: center;
      background: #a88b61;
      cursor: pointer;
    }
  }
}
</style>