<template>
    <div class="_wrap">
        <div class="_auto">
            <!-- <myBread name="石窟考古" path='/lmarch' :child="detail.title" childPath='/lmarchDetail'></myBread> -->
            <myBread name="保护研究" path='/research' child="数字龙门" childPath='/diglm' :tChild='detail.title' tChildPath='/numDetail'></myBread>
 
            <div class="_content" >
                <h4>{{detail.title}}</h4>

                <div class="_html" v-if="detail.content !=''" ref="myHtml" v-html="detail.content">

                  
                </div>

            <div v-else class="_noData"> 暂无内容...</div>

            </div>


        </div>
    </div>
</template>

<script>
import {getDetail} from '@/api/getData'
export default {    
    data(){
        return{
            tit:"",
            id:"",
            detail:{}
        }
    },
          // 动态设置页面标题、关键字和描述等属性  
          metaInfo() {

return {
  title: `${this.detail.title}_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
    async mounted(){
        let tit = this.$route.query.tit
        let id = this.$route.query.id
        this.tit = tit
        this.id = id
        await this.getDetail()
            setTimeout(() => {
      this.$scallHtmlImg();
    }, 50);


    },
    methods:{
        async getDetail(){
            const params = {
                id:this.id
            }
            await getDetail(params).then(res=>{
                this.detail = res.data
            })
        }
    }

}
</script>

<style lang="scss" scoped>
._noData{
    text-align: center;
}
._content
{
    padding: 0 60px;
    min-height: 500px;
    h4
    {
        text-align: center;
        font-size: 26px;
        color: #333;
        margin-bottom: 40px;
    }
}

::v-deep ._html p{
    line-height: 36px;
}
</style>