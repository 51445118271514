<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="文旅融合" path='/cultural' child="研学" childPath='/studyInfo'></myBread>

      <div class="_top">
        <div class="_tabs1">
          <ul>
            <li>研学</li>
          </ul>
        </div>
      </div>

      <div class="_yxList">
        <div
          class="_li"
          v-for="(item, index) in list"
          :key="index"
          @click="goDetail(item)"
        >
          <div class="_img">
            <img :src="$IMG_URL + item.cover" alt="" />
          </div>
          <div class="_sub">
            <h4>{{ item.title }}</h4>
            <p>{{ item.pub_time }}</p>
          </div>
        </div>

      </div>

      <div class="_page">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.pagesize"
          @pagination="getList"
        />
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle, addHit } from "@/api/getData";
export default {
  data() {
    return {
      list: [],
      total: 100,
      queryParams: {
        cate_id: "18",
        page: 1,
        pagesize: 6,
      },
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `研学_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  async mounted() {
    await this.getList();

  },
  methods: {
    async getList() {
      await getArticle(this.queryParams).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
    goDetail(item) {
      const params = {
        id: item.id,
      };
      addHit(params).then(() => {
        let url = location.origin;

        if (item.link != "" && item.link != null) {
          window.open(item.link);
        } else{
          window.open(`${url}/#/studyDetail?id=${item.id}`);

        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
._page {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

._top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  border-top: 1px solid #a88b61;
  margin: 0 20px;

  ._tabs1 {
    ul {
      display: flex;

      li {
        color: #333;
        font-size: 22px;
        padding: 14px 30px;
        cursor: pointer;
        background-color: #a88b61;
        color: white;
      }
    }
  }

  ._more {
    margin-right: 20px;
  }
}

._yxList {
  margin: 0 20px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  ._li {
    cursor: pointer;
    margin-right: 16px;
    margin-bottom: 20px;
    ._img {
      width: 400px;
      height: 245px;
      overflow: hidden;
      border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.5s;
      }

      img:hover {
        transform: scale(1.1);
      }
    }

    ._sub {
      border-left: 2px solid #a88b61;
      padding-left: 10px;
      margin-top: 10px;

      h4 {
        font-size: 20px;
        color: #333;
        margin-bottom: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        color: #999999;
      }
    }
  }

  ._li:nth-child(3n){
    margin-right: 0;
  }
}
</style>