<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="互动交流" path='/exchange' child="陈列外展" childPath='/exhibitInfo'></myBread>

      <div class="_top">
        <div class="_tabs">
          <ul>
            <li>陈列外展</li>
          </ul>
        </div>
      </div>

      <div class="_list">
        <div
          class="_li"
          v-for="(item, index) in list"
          :key="index"
          @click="goDetail(item)"
        >
          <div class="_img">
            <img :src="$IMG_URL + item.cover" alt="" />
          </div>
          <h4>{{ item.title }}</h4>
          <p>{{ item.sub_title }}</p>
        </div>
      </div>

      <div class="_page">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.pagesize"
          @pagination="getList"
        />
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle,addHit } from "@/api/getData";
export default {
  data() {
    return {
      list: [],
      total: 0,
      queryParams: {
        cate_id: "44",
        page: 1,
        pagesize: 6,
      },
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `陈列外展_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  async mounted() {
    await this.getList();
    
 
  },
  methods: {
    goDetail(item) {
      let url = location.origin;

      const params = {
        id: item.id,
      };

      addHit(params);
      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/exhibitDetail?id=${item.id}`);
      }
    },
    async getList() {
      await getArticle(this.queryParams).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
._page {
  text-align: center;
  margin-bottom: 20px;
}
._top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #a88b61;
  margin: 0 20px;
  ._tabs {
    ul {
      display: flex;

      li {
        font-size: 22px;
        color: #333;
        padding: 14px 30px;
        cursor: pointer;
        background-color: #a88b61;
        color: white;
      }
    }
  }
}

._list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px;
  margin-top: 20px;
  ._li {
    margin-right: 20px;
    width: 32.2%;
    margin-bottom: 57px;
    cursor: pointer;
    ._img {
      width: 100%;
      height: 245px;
      overflow: hidden;
      border-radius: 5px;

    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s;
    }
    img:hover {
      transform: scale(1.1);
    }
    h4 {
      font-size: 20px;
      color: #333;
      margin-top: 10px;
    }
    p {
      color: #999999;
    }
  }
  ._li:nth-child(3n) {
    margin-right: 0;
  }
}
</style>