<template>
  <div id="app" data-server-rendered="true">
    <!-- <router-view> </router-view>- -->
    <layout></layout>
  </div>
</template>

<script>
import layout from "./views/layout.vue";
export default {
  name: "App",
  components: {
    layout,
  },

  created() {
    this.$store.dispatch("GETWEBDATA");
  },
};
</script>
<style lang="scss">
._auto {
  max-width: 1280px;
  margin: 0 auto;
  background: white;
  overflow: hidden;
}

._impact1 {
  font-family: Impact;
}
.el-popup-parent--hidden {
  padding-right: 0px !important;
  overflow: hidden !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: "阿里巴巴普惠体 2.0 55 Regular";
}

body {
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
