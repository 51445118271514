<template>
  <div class="_bread">
    <div class="_z_t">
      <p>当前位置：</p>
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: path }">{{ name }}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="child.length > 0">{{
          child
        }}</el-breadcrumb-item>
      </el-breadcrumb> -->

      <div class="_bread_cont">
        <p @click="$router.push({ path: '/' })">
          <span> 首页 </span>
          <img src="../../assets/image/home-img/bread.png" alt="" />
        </p>
        <p @click="goPath" class="_p1">
          <span>{{ name }}</span>
          <img
            v-if="child.length > 0"
            src="../../assets/image/home-img/bread.png"
            alt=""
          />
        </p>
        <p v-if="child.length > 0" class="_p2" @click="goChild">
          <span>{{ child }}</span>
          <img  v-if="tChild.length > 0" src="../../assets/image/home-img/bread.png" alt="" />
        </p>

        <p class="_p3" v-if="tChild.length > 0" @click="goTchild">
          <span>{{ tChild }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    path: {
      type: String,
    },
    child: {
      type: String,
      default: "",
    },
    childPath: {
      type: String,
    },
    tChild: {
      type: String,
      default: "",
    },
    tChildPath: {
      type: String,
    },
  },

  methods: {
    goPath() {
      if (this.path == this.$route.path) {
        location.reload();
      } else {
        this.$router.push({
          path: this.path,
        });
      }
    },

    goChild() {
      if (this.childPath == this.$route.path) {
        location.reload();
      } else {
        this.$router.push({
          path: this.childPath,
        });
      }
    },
    goTchild() {
      if (this.tChildPath == this.$route.path) {
        location.reload();
      } else {
        this.$router.push({
          path: this.tChildPath,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
._z_t {
  display: flex;

  align-items: center;

  > p {
    color: #666;
    font-size: 14px;
  }
}

._bread {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 20px;
  background-color: #f9f9f9;
  padding-left: 20px;
}

.el-breadcrumb {
  line-height: normal;
}

.el-breadcrumb__item {
  color: #666;
}

::v-deep .el-breadcrumb__inner {
  color: #666 !important;
  font-weight: normal;
}

._bread_cont {
  display: flex;
  margin-left: 10px;
  img {
    width: 15px;
    margin: 0 6px;
  }
  p {
    color: #666;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  ._p1 {
  }
}
</style>