import 'babel-polyfill';


// import Es6Promise from 'es6-promise' // 引入es6-promise模块
// Es6Promise.polyfill() // 挂载模块
require('es6-promise').polyfill();
require('es6-promise/auto');
import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import { IMG_URL } from '@/axios/base_api'





if (Number.parseInt === undefined) Number.parseInt = window.parseInt;
if (Number.parseFloat === undefined) Number.parseFloat = window.parseFloat;


import store from "./store/index"
Vue.prototype.$IMG_URL = IMG_URL
Vue.config.productionTip = false;
Vue.use(ElementUI);
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
import Pagination from "@/components/Pagination";
import mainFooter from '@/components/footer/footer'
import otherBwg from '@/components/otherBwg/otherBwg'
import myBread from "@/components/myBread/myBread";
Vue.component('Pagination', Pagination)
Vue.component('mainFooter', mainFooter)
Vue.component('otherBwg', otherBwg)
Vue.component('myBread', myBread)




import "./assets/css/reset.css"

import 'swiper/css/swiper.min.css'
import { scrollToSection } from './utils/common'

import { scallHtmlImg } from './utils/scallImg'

Vue.prototype.$scrollToSection = scrollToSection
Vue.prototype.$scallHtmlImg = scallHtmlImg


Vue.prototype.shareWb = function (url, title, pic) {
  window.open(`http://service.weibo.com/share/share.php?url=${encodeURIComponent(url)}&title=${title}&pic=${pic}`)

}
Vue.prototype.shareQQ = function (url, title, desc, pics) {
  window.open(`https://connect.qq.com/widget/shareqq/index.html?url=${encodeURIComponent(url)}&title=${title}&summary=${desc}&pics=${pics}`)

}




new Vue({
  router,
  store,
  render: (h) => h(App),

}).$mount("#app");
