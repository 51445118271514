<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="文旅融合" path='/cultural'></myBread>

      <div class="_top">
        <div class="_tabs1">
          <ul>
            <li>走进龙门</li>
          </ul>
        </div>
        <div class="_more" @click="golm">MORE</div>
      </div>

      <div class="_lmList">
        <div
          class="_li"
          v-for="(item, index) in zjlmList"
          :key="index"
          @click="goDetail(item)"
        >
          <img :src="$IMG_URL + item.cover" alt="" />
          <p>
            {{ item.title }}
          </p>
        </div>
      </div>

      <div class="_top">
        <div class="_tabs1">
          <ul>
            <li>研学</li>
          </ul>
        </div>
        <div class="_more" @click="goyx">MORE</div>
      </div>

      <div class="_yxList">
        <div
          class="_li"
          v-for="(item, index) in yxList"
          :key="index"
          @click="goyxDetail(item)"
        >
          <div class="_img">
            <img :src="$IMG_URL + item.cover" alt="" />
          </div>
          <div class="_sub">
            <h4>{{ item.title }}</h4>
            <p>{{ item.pub_time }}</p>
          </div>
        </div>
      </div>

      <div class="_top">
        <div class="_tabs1">
          <ul>
            <li>文创产品</li>
          </ul>
        </div>
        <div class="_more" @click="gowc">MORE</div>
      </div>

      <div class="_wcList">
        <div
          class="_li"
          v-for="(item, index) in wccpList"
          :key="index"
          @click="goWcDetail(item)"
        >
          <div class="_img">
            <img :src="$IMG_URL + item.cover" alt="" />
          </div>
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle, addHit } from "@/api/getData";
export default {
  data() {
    return {
      zjlmList: [],
      yxList: [],
      wccpList: [],
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `文旅融合_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  async mounted() {
    await this.getData();
    

  },
  methods: {
    golm() {
      let url = location.origin;
      window.open(`${url}/#/enterLmInfo`);
    },
    goyx() {
      let url = location.origin;
      window.open(`${url}/#/studyInfo`);
    },
    gowc() {
      let url = location.origin;
      window.open(`${url}/#/industriesInfo`);
    },
    goDetail(item) {
      const params = {
        id: item.id,
      };
      addHit(params).then(() => {

        let url = location.origin;
        
        if (item.link != "" && item.link != null) {
          window.open(item.link);
        } else {
          window.open(`${url}/#/enterLmDetail?id=${item.id}`);

        }
      });
    },
    goyxDetail(item) {
      const params = {
        id: item.id,
      };
      addHit(params).then(() => {
        let url = location.origin;

        if (item.link != "" && item.link != null) {
          window.open(item.link);
        } else {
          window.open(`${url}/#/studyDetail?id=${item.id}`);

        }
      });
    },
    goWcDetail(item) {
      const params = {
        id: item.id,
      };
      addHit(params).then(() => {
        let url = location.origin;
        if (item.link != "" && item.link != null) {
          window.open(item.link);
        } else{
          window.open(`${url}/#/industriesDetail?id=${item.id}`);

        }
      });
    },

    async getData() {
      const params = {
        cate_id: "17",
        page: 1,
        pagesize: 4,
      };

      await getArticle(params).then((res) => {
        this.zjlmList = res.data.data;
      });

      const params1 = {
        cate_id: "18",
        page: 1,
        pagesize: 3,
      };

      await getArticle(params1).then((res) => {
        this.yxList = res.data.data;
      });
      const params2 = {
        cate_id: "19",
        page: 1,
        pagesize: 8,
      };

      await getArticle(params2).then((res) => {
        this.wccpList = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
._more {
  border: 1px solid #bb945b;
  color: #303030;
  font-size: 14px;
  padding: 8px 14px;
  cursor: pointer;
}

._top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  border-top: 1px solid #a88b61;
  margin: 0 20px;

  ._tabs1 {
    ul {
      display: flex;

      li {
        color: #333;
        font-size: 22px;
        padding: 14px 30px;
        cursor: pointer;
        background-color: #a88b61;
        color: white;
      }
    }
  }

  ._more {
    margin-right: 20px;
  }
}

._lmList {
  margin: 0 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;

  ._li {
    width: 299px;
    height: 240px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s;
    }

    img:hover {
      transform: scale(1.1);
    }

    p {
      position: absolute;
      bottom: 20px;
      width: 100%;
      text-align: center;
      color: white;
      font-weight: bold;
      font-size: 18px;
    }
  }
}

._yxList {
  margin: 0 20px;
  margin-top: 20px;
  display: flex;
  //   justify-content: space-between;

  margin-bottom: 60px;

  ._li {
    cursor: pointer;
    margin-right: 16px;
    ._img {
      width: 400px;
      height: 245px;
      overflow: hidden;
      border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.5s;
      }

      img:hover {
        transform: scale(1.1);
      }
    }

    ._sub {
      border-left: 2px solid #a88b61;
      padding-left: 10px;
      margin-top: 10px;

      h4 {
        font-size: 20px;
        color: #333;
        margin-bottom: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        color: #999999;
      }
    }
  }
  ._li:nth-child(3) {
    margin-right: 0;
  }
}

._wcList {
  margin: 0 20px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 51px;
  ._li {
    width: 295px;
    margin-bottom: 20px;
    cursor: pointer;

    ._img {
      width: 295px;
      height: 188px;
      overflow: hidden;
      border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.5s;
      }

      img:hover {
        transform: scale(1.1);
      }
    }

    p {
      background: #f9f9f9;
      width: 100%;
      padding: 10px 0;
      text-align: center;
      font-size: 20px;
      color: #333;
    }
  }
}
</style>