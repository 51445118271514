<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="保护研究" path='/research' child="石窟考古" childPath='/lmarch'></myBread>

      <div class="_qt">
        <div class="_top">
          <div class="_tabs1">
            <ul>
              <li>石窟考古</li>
            </ul>
          </div>

          <div class="_list">
            <div
              class="_li"
              v-for="(item, index) in list"
              :key="index"
              @click="goDetail(item)"
            >
              <div class="_img">
                <img :src="$IMG_URL + item.cover" alt="" />
              </div>
              <div class="_bb">
                <h4>{{ item.title }}</h4>
                <p class="_sub">
                  {{ item.descs }}
                </p>
                <p class="xq">查看详情 --></p>
              </div>
            </div>
          </div>
          <div class="_page">
            <pagination
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.page"
              :limit.sync="queryParams.pagesize"
              @pagination="getList"
            />
          </div>
        </div>
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle,addHit } from "@/api/getData";
export default {
  data() {
    return {
      list: [],
      total: 0,
      queryParams: {
        cate_id: "36",
        page: 1,
        pagesize: 5,
      },
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `石窟考古_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  created() {},
  async mounted() {
    await this.getList();

  },
  methods: {
    goDetail(item) {
      let url = location.origin;
        const params = {
            id:item.id
        }
      addHit(params);
      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/lmarchDetail?id=${item.id}&tit=石窟考古`);
      }
    },
    async getList() {
      await getArticle(this.queryParams).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
._page {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

._qt {
  margin-top: 20px;
  min-height: 500px;
  ._top {
    border-top: 1px solid #a88b61;
    margin: 0 20px;

    ._tabs1 {
      background: #f9f9f9;

      ul {
        display: flex;

        li {
          color: #333;
          font-size: 22px;
          padding: 14px 30px;
          background-color: #a88b61;
          color: white;
        }
      }
    }

    ._more {
      margin-right: 20px;
    }
  }

  ._list {
    ._li {
      cursor: pointer;
      padding: 20px 20px 20px 0;
      display: flex;
      border-bottom: 1px dashed #e2e2e2;
      align-items: center;
      ._img {
        overflow: hidden;
        width: 234px;
        height: 145px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s;
        }

        img:hover {
          transform: scale(1.1);
        }
      }

      ._bb {
        flex: 1;
        margin-left: 30px;

        ._sub {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* 设置最大显示行数 */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          color: #888;
          line-height: 26px;
        }
      }

      h4 {
        font-size: 20px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        font-size: 16px;
        color: #666;
        margin-top: 10px;
      }

      .xq {
        color: #a88b61;
      }
    }

    ._li:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>