<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread
        name="保护研究"
        path="/research"
        child="数字龙门"
        childPath="/diglm"
        tChild="视频展示"
        tChildPath="/videoInfo"

      ></myBread>

      <div class="_qt">
        <div class="_top">
          <div class="_tabs1">
            <ul>
              <li
                :class="{ _active: current1 == index }"
                v-for="(item, index) in tabsList1"
                :key="index"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>

          <div class="_list">
            <div
              class="_li"
              v-for="(item, index) in changeList"
              :key="index"
              @click="lookVideo(item)"
            >
              <div class="_img">
                <img class="_ii" :src="$IMG_URL + item.cover" alt="" />

                <img
                  class="_bf"
                  src="../../assets/image/research-img/bf.png"
                  alt=""

                />
              </div>
              <div class="_bb">
                <h4>{{ item.title }}</h4>
                <p>{{ item.pub_time }}</p>
              </div>
            </div>
          </div>
          <div class="_page">
            <pagination
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.page"
              :limit.sync="queryParams.pagesize"
              @pagination="getList"
            />
          </div>
        </div>
      </div>
    </div>

    <otherBwg></otherBwg>

    <videoBox ref="videoBoxRef"></videoBox>
  </div>
</template>

<script>
import { getArticle,addHit } from "@/api/getData";

import videoBox from "@/components/diaVideo/dieVideo.vue";
export default {
  components: {
    videoBox,
  },
  data() {
    return {
      changeList: [],
      current1: 0,
      tabsList1: [

        {
          name: "视频展示",
        },
      ],

      total: 0,
      queryParams: {
        cate_id: "41",
        page: 1,
        pagesize: 9,
      },
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: `数字龙门_龙门石窟研究院_官网`,
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  async mounted() {
    let tit = this.$route.query.tit;
    if (tit == "数字展示") {
      this.current1 = 0;
      this.queryParams.cate_id = "41";
    } else if (tit == "视频展示") {
      this.current1 = 1;
      this.queryParams.cate_id = "42";
    }
    await this.getList();
  },
  methods: {
    async changeTab1(item, index) {
      this.current1 = index;
      if (index == 0) {
        this.queryParams.cate_id = "41";
      } else {
        this.queryParams.cate_id = "42";
      }

      await this.getList();
    },
    async getList() {
      await getArticle(this.queryParams).then((res) => {
        console.log(res);
        this.changeList = res.data.data;
        this.total = res.data.total;
      });
    },
    lookVideo(item) {
      let url = location.origin;
      const params = {
        id: item.id,
      };
      addHit(params);
      this.$refs.videoBoxRef.edit(item);

      return
      if (item.c_id == "42") {
        this.$refs.videoBoxRef.edit(item);
      } else {
        if (item.link != "" && item.link != null) {
          window.open(item.link);
        } else {
          window.open(`${url}/#/numDetail?id=${item.id}`);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
._qt {
  margin-top: 40px;

  ._top {
    border-top: 1px solid #a88b61;
    margin: 0 20px;

    ._tabs1 {
      background: #f9f9f9;

      ul {
        display: flex;

        li {
          color: #333;
          font-size: 22px;
          padding: 14px 30px;
          background-color: #a88b61;
          color: white;


        }

        ._active {
          background-color: #a88b61;
          color: white;
        }
      }
    }

    ._more {
      margin-right: 20px;
    }
  }

  ._list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;

    ._li {
      margin-right: 20px;
      width: 32.2%;
      margin-bottom: 20px;
      cursor: pointer;

      ._img {
        overflow: hidden;
        width: 100%;
        height: 240px;
        position: relative;
        ._ii {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s;
        }

        ._ii:hover {
          transform: scale(1.1);
        }

        ._bf {
          width: 32px;
          height: 32px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -16px;
          margin-top: -16px;
        }
      }

      ._bb {
        background-color: #f9f9f9;
        padding: 10px 16px;
      }

      h4 {
        font-size: 18px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        font-size: 14px;
        color: #999;
      }
    }

    ._li:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>