<template>
  <div class="_wrap">
    <div class="_auto">
      <!-- <myBread name="走进龙门" path="/enterLmInfo" :child="detail.title" childPath='/enterLmDetail'></myBread> -->
      <myBread name="文旅融合" path='/cultural' child='走进龙门' childPath='/enterLmInfo' :tChild='detail.title' tChildPath='/enterLmDetail'></myBread>


      <div class="_content">
        <div class="_cont">
          <h4>{{ detail.title }}</h4>
          <div class="_html" ref="myHtml" v-html="detail.content"></div>
        </div>

        <div class="_tj">
          <h4>精彩图集</h4>

          <div class="_tjList">
            <div class="_img" v-for="(item, index) in tujiList" :key="index">
              <img :src="$IMG_URL + item" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getDetail } from "@/api/getData";
export default {
  data() {
    return {
      detail: {},
      id: "",
      tujiList: [],
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `${this.detail.title}_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
 async mounted() {
    this.id = this.$route.query.id;
   await this.getDetail();
        setTimeout(() => {
      this.$scallHtmlImg();
    }, 50);
    

  },
  methods: {
    async getDetail() {
      const params = {
        id: this.id,
      };
      await getDetail(params).then((res) => {
        this.detail = res.data;
        this.tujiList = this.detail.tuji.split(",");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
._content {
  ._cont {
    margin: 0 60px;
    margin-bottom: 50px;
    background: #f9f9f9;
    padding: 30px 40px;

    h4 {
      font-size: 26px;
      color: #333;
      text-align: center;
      margin-bottom: 30px;
    }
  }
}

._tj {
  margin: 0 60px;
  h4 {
    text-align: center;
    font-size: 26px;
    color: #a88b61;
    display: flex;

    align-items: center;
    justify-content: center;
  }
  h4::before {
    content: "";
    display: inline-block;
    width: 91px;
    height: 1px;
    margin-right: 20px;
    background: linear-gradient(
      90deg,
      rgba(168, 139, 97, 0) 0%,
      rgba(168, 139, 97, 0.5) 100%
    );
  }

  h4::after {
    content: "";
    display: inline-block;
    width: 91px;
    height: 1px;
    margin-left: 20px;

    background: linear-gradient(
      90deg,
      rgba(168, 139, 97, 0.5) 0%,
      rgba(168, 139, 97, 0) 100%
    );
  }

  ._tjList {
    column-count: 3;
    column-gap: 38px;
    // margin: 0 60px;
    margin-top: 30px;
    padding-bottom: 60px;

    ._img {
      width: 360px;
      height: auto;
      margin-bottom: 10px;
      img{
        width: 100%;
        height: auto;
      }
    }
  }
}
::v-deep ._html p {
  line-height: 36px;
}
</style>