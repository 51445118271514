<template>

  <div :class="{ 'hidden': hidden }" class="pagination-container">
    <div class="_s" @click="goFist">首页</div>

    <el-pagination :background="background" :current-page.sync="currentPage" :page-size.sync="pageSize" :layout="layout"
      :page-sizes="pageSizes" :pager-count="pagerCount" :total="total" v-bind="$attrs" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" />

    <div class="_s" @click="goLast">尾页</div>
  </div>
</template>

<script>
// import { scrollTo } from '@/utils/scroll-to'

export default {
  name: 'Pagination',
  props: {

    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50]
      }
    },
    // 移动端页码按钮的数量端默认值5
    pagerCount: {
      type: Number,
      default: document.body.clientWidth < 992 ? 5 : 7
    },
    layout: {
      type: String,
      // default: 'total, prev, pager, next, jumper'
      default: 'prev, pager, next'

    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      if (this.currentPage * val > this.total) {
        this.currentPage = 1
      }
      this.$emit('pagination', { page: this.currentPage, limit: val })
      // if (this.autoScroll) {
      //   scrollTo(0, 800)
      // }
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
      // if (this.autoScroll) {
      //   scrollTo(0, 800)
      // }
    },

    goFist(){
      this.currentPage = 1
      this.$emit('pagination', { page: this.currentPage, limit: this.pageSize })
    },
    goLast(){
      console.log(this.total)
      console.log(this.pageSize)
      let lastPage = Math.ceil(this.total / this.pageSize);
      this.currentPage = lastPage
       this.$emit('pagination', { page: lastPage, limit: this.pageSize })
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container
{
  /* background: #fff; */
  padding: 40px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-container.hidden
{
  display: none;
}

::v-deep .el-pager li
{
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 8px;
  border: 1px solid #ECEBEB;
  font-size: 18px;
  background-color: white !important;
  color: #999999 !important;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active
{
  background: $hover-color !important;
}

::v-deep .el-pager .more
{
  border: none;
  background-color: transparent;
}

::v-deep .el-pagination .btn-prev
{
  width: 68px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #E0E0E0;
  background: white;
}

::v-deep .el-pagination .btn-next
{
  width: 68px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #E0E0E0;
  background: white;

}

::v-deep .el-pager li.active+li
{
  border-left: 1px solid #E0E0E0;
}

::v-deep .el-icon-arrow-left
{
  font-size: 18px !important;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active
{
  color: #A88B61 !important;
  background: #F8F5EA !important;
}

::v-deep .el-icon-arrow-left:before
{
  content: "上一页";
  font-size: 16px;
  font-weight: normal;
}

::v-deep .el-icon-arrow-right:before
{
  content: "下一页";
  font-size: 16px;
  font-weight: normal;
}

._s
{
  width: 52px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ECEBEB;
  color: #999;
  cursor: pointer;
  text-align: center;
}
</style>
