<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"

    :before-close="handleClose"
  >
    <div class="_html" v-html="content"></div>

  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      title:"",
      content:"",
    };
  },
  methods: {
    handleClose() {
        this.dialogVisible = false
    },

    edit(data){
        this.content = data.content
        this.title = data.c_name
        this.dialogVisible = true
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog{
    background-image: url("../../assets/image/ins-img/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    box-shadow: none;

    width: 704px !important;
    height: 430px ;

}
._html{
  height: 264px;
  overflow-y: auto;
  padding: 0 33px;
}
._html::-webkit-scrollbar
{
  width: 8px;
  height: 10px;
  background-color: transparent;
  display: none;
}

._html::-webkit-scrollbar-thumb
{
  border-radius: 20px;
  background-color: rgba(144, 147, 153, .3);
}

._html{
  -ms-overflow-style: none; /* IE 和 Edge */
}



::v-deep .el-dialog__headerbtn{
    display: none;
}

::v-deep .el-dialog__title{
    
    display: block;
    font-size: 20px;
    color: #A88B61;
    font-weight: bold;
    padding: 40px 0 18px 0;
    border-bottom: 1px solid #A88B61;
    line-height: normal;
    width: 100%;
}
::v-deep .el-dialog__header{
    padding: 0;
    width: 83.5%;
    margin-left: 50px;
    

}
::v-deep .el-dialog__body{
    padding: 0;
    color: #333;
    font-size: 16px;
    padding-left: 20px;
}
._html{
    margin-top: 18px;
}
::v-deep ._html p{
    line-height: 32px;
    
}
</style>