<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="互动交流" path='/exchange' child="院藏展品" childPath='/concealInfo'></myBread>

      <div class="_cs">
        <div class="_top">
          <div class="_tabs">
            <ul>
              <li>院藏展品</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="_search">
        <div class="_ll">
          <el-form
            ref="form"
            label-width="0"
            class="_ttt"
            label-position="left"
          >
            <div>
              <el-form-item>
                <el-select
                  v-model="queryParams.year"
                  placeholder="请选择年代"
                  style="width: 140px"
                >
                  <el-option
                    v-for="(item, index) in params.year"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>

            <div>
              <el-form-item>
                <el-select
                  v-model="queryParams.material"
                  placeholder="请选择材质"
                  style="width: 140px"
                >
                  <el-option
                    v-for="(item, index) in params.material"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>

            <div>
              <el-form-item>
                <el-select
                  v-model="queryParams.from"
                  placeholder="请选择来源"
                  style="width: 140px"
                >
                  <el-option
                    v-for="(item, index) in params.from"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </div>

        <div class="_rr">
          <div class="_ipt">
            <el-input
              v-model="queryParams.title"
              placeholder="请输入文物关键字"
              style="width: 320px"
            ></el-input>
            <img src="../../assets/image/exchange-img/s.png" alt="" />
          </div>
          <div class="_btn">
            <div class="_bt1" @click="search">搜索</div>
            <div class="_bt2" @click="reset">重置</div>
          </div>
        </div>
      </div>

      <div class="_ycList">
        <div
          class="_li"
          v-for="(item, index) in list"
          :key="index"
          @click="goCpDetail(item)"
        >
          <div class="_img">
            <img :src="$IMG_URL + item.file" alt="" />
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>

      <div class="_page">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.pagesize"
          @pagination="getList"
        />
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getRelic, reHit, getParams } from "@/api/getData";
export default {
  data() {
    return {
      list: [],
      total: 0,
      params: {},
      queryParams: {
        page: 1,
        pagesize: 12,
        year: "",
        material: "",
        from: "",
        title: "",
      },
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `院藏展品_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  async mounted() {
    this.getParams();
    await this.getList();


  },
  methods: {
    goCpDetail(item) {
      const params = {
        id: item.id,
      };
      reHit(params).then((res) => {
        let url = location.origin;

        if (item.link != "" && item.link != null) {
          window.open(item.link);
        } else {
          window.open(`${url}/#/concealDetail?id=${item.id}`);
        }
      });
    },

    async getList() {
      await getRelic(this.queryParams).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },

    getParams() {
      getParams().then((res) => {
        this.params = res.data;
      });
    },

    search() {
      this.queryParams.page = 1;
      this.getList();
    },
    reset() {
      this.queryParams = {
        page: 1,
        pagesize: 12,
        year: "",
        material: "",
        from: "",
        title: "",
      };
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
._page {
  text-align: center;
}

._cs {
  padding: 0 20px;
}

._top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #a88b61;

  ._tabs {
    ul {
      display: flex;

      li {
        font-size: 22px;
        color: #333;
        padding: 14px 30px;
        cursor: pointer;
        background-color: #a88b61;
        color: white;
      }
    }
  }
}

._search {
  margin: 0 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  ._ttt {
    display: flex;

    > div {
      margin-right: 20px;
    }
  }

  ._rr {
    display: flex;

    ._ipt {
      position: relative;

      img {
        position: absolute;
        top: 12px;
        left: 10px;
        width: 15px;
      }
    }

    ._btn {
      display: flex;

      > div {
        width: 90px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
        text-align: center;
        border-radius: 6px 6px 6px 6px;
        cursor: pointer;
      }

      ._bt1 {
        background: #a88b61;
        color: white;
        margin-left: 10px;
        margin-right: 10px;
      }

      ._bt2 {
        background: #f6f5f0;
        color: #666;
      }
    }
  }
}

._ycList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px;
  margin-top: 23px;

  ._li {
    margin-right: 15px;
    margin-bottom: 20px;
    cursor: pointer;

    ._img {
      width: 400px;
      height: 300px;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.5s;
      }

      img:hover {
        transform: scale(1.1);
      }
    }

    p {
      font-size: 22px;
      color: #333;
      text-align: center;
      position: absolute;
      bottom: 20px;
      width: 100%;
      text-align: center;
    }
  }

  ._li:nth-child(3n) {
    margin-right: 0;
  }
}

::v-deep ._ipt .el-input .el-input__inner {
  padding-left: 30px;
}
</style>