<template>
  <div class="_home">
    <div class="_auto">
      <div class="_news">
        <div class="_left" @click="goDetail(artList[0])">
          <div class="_imgBox" v-if="artList.length > 0">
            <img :src="$IMG_URL + artList[0].cover" alt="龙门石窟研究院官网" />
          </div>
          <h4 v-if="artList.length > 0">{{ artList[0].title }}</h4>
        </div>
        <div class="_right">
          <div class="_top">
            <div class="_tabs">
              <ul>
                <li
                  :class="{ _active: current == index }"
                  v-for="(item, index) in tabsList"
                  :key="index"
                  @click="changeTab(item, index)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="_more" @click="lookMore">MORE</div>
          </div>

          <div class="_bot">
            <ul>
              <li
                v-for="(item, index) in artList"
                :key="index"
                @click="goDetail(item)"
              >
                <h4>
                  <span>·</span>
                  <p class="_p">{{ item.title }}</p>
                </h4>
                <p class="_time">{{ item.pub_time }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="_clList">
        <div class="swiper mySwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in waizhanList"
              :key="index"
              @click="goOther(item)"
              style="cursor: pointer"
            >
              <div class="_img">
                <img :src="$IMG_URL + item.cover" alt="" />
              </div>
            </div>
          </div>
          <div class="swiper-scrollbar"></div>
        </div>
      </div>

      <div class="_noList">
        <div class="_lf">
          <div class="_top">
            <div class="_tabs1">
              <ul>
                <li
                  :class="{ _active: current1 == index }"
                  v-for="(item, index) in tabsList1"
                  :key="index"
                  @click="changeTab1(item, index)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="_more" @click="lookMore1">MORE</div>
          </div>

          <div class="_list">
            <div v-if="queryParams1.cate_id == '29'">
              <div
                :class="{ _li: true }"
                v-for="(item, index) in xsList"
                :key="index"
              >
                <h4 :class="{ jjh4: current1 == 0 }">{{ item.title }}</h4>

                <p>
                  <span
                    >{{ item.types }} / {{ item.author_name }} /
                    {{ item.publish_time }}年出版 / {{ item.open_book }}</span
                  >
                </p>

                <!-- <p v-else>
                时间：{{ item.pub_time }}
              </p> -->
              </div>
            </div>

            <div v-else>
              <div
                class="_li _ll1"
                v-for="(item, index) in xsList"
                :key="index"
                @click="goskbhDetail(item)"
              >
                <h4>
                  {{ item.title }}
                </h4>

                <p>
                  {{ item.pub_time }}
                </p>
              </div>
            </div>
          </div>
          <div class="_bb">
            <img
              src="../assets/image/home-img/Group 902.png"
              alt="龙门石窟研究院官网"
              @click="$router.push({ path: '/diglm' })"
            />
            <img
              src="../assets/image/home-img/Group 903.png"
              alt="龙门石窟研究院官网"
              @click="$router.push({ path: '/research' })"
            />
          </div>
        </div>

        <div class="_rt">
          <div class="_top">
            <h4>互动交流</h4>
            <div class="_more" @click="goHdJl">MORE</div>
          </div>

          <div class="_rtList">
            <div
              class="_rtLi"
              v-for="(item, index) in hdList"
              :key="index"
              @click="gohdjlDetail(item)"
            >
              <div class="_imgBox">
                <img :src="$IMG_URL+item.cover" alt="龙门石窟研究院官网" />
              </div>
              <h4>{{ item.title }}</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="_lmList _noList">
        <div class="_lf">
          <div class="_top">
            <div class="_tabs1">
              <ul>
                <li
                  :class="{ _active: current2 == index }"
                  v-for="(item, index) in tabsList2"
                  :key="index"
                  @click="changeTab2(item, index)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="_more" @click="lookMore2">MORE</div>
          </div>

          <div class="_bo">
            <div class="_div" v-show="current2 == 0">
              <div class="_lli">
                <h4
                  v-for="(item, index) in lmList"
                  :key="index"
                  @click="goLmDetail(item)"
                >
                  {{ item.title }}
                </h4>
              </div>

              <div class="_lli">
                <h4
                  v-for="(item, index) in lmList1"
                  :key="index"
                  @click="goLmDetail(item)"
                >
                  {{ item.title }}
                </h4>
              </div>
              <div class="_lli">
                <h4
                  v-for="(item, index) in lmList2"
                  :key="index"
                  @click="goLmDetail(item)"
                >
                  {{ item.title }}
                </h4>
              </div>
            </div>

            <div class="_div1" v-show="current2 == 1">
              <div
                class="_li"
                v-for="(item, index) in yxList"
                :key="index"
                @click="goYxDetail(item)"
              >
                <h4>{{ item.title }}</h4>
                <p>
                  {{ item.pub_time }}
                </p>
              </div>
            </div>

            <div class="_div2" v-show="current2 == 2">
              <div
                class="_li"
                v-for="(item, index) in wcList"
                :key="index"
                @click="gowcDetail(item)"
              >
                <div class="_img">
                  <img :src="$IMG_URL+item.cover" alt="" />
                </div>
                <p>{{ item.title }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="_rt">
          <div class="_top">
            <h4>新媒体矩阵</h4>
            <!-- <div class="_more">MORE</div> -->
          </div>

          <div class="_qrcode">
            <div class="_l">
              <img src="../assets/image/home-img/gongzhonghao.png" alt="" />
              <p>公众号 · 扫码加关注</p>
            </div>
            <div class="_xian"></div>
            <div class="_r">
              <img src="../assets/image/home-img/shipinhao.png" alt="" />
              <p>视频号 · 扫码看直播</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <other-bwg></other-bwg>
  </div>
</template>

<script>
import Swiper from "swiper";

import { getArticle, addHit } from "@/api/getData";
export default {
  data() {
    return {
      current: 0,
      current1: 0,
      current2: 0,
      artList: [],
      waizhanList: [],
      xsList: [],
      hdList: [],
      lmList: [],
      lmList1: [],
      lmList2: [],
      yxList: [],
      wcList: [],
      tabsList: [
        {
          name: "工作动态",
        },
        {
          name: "通知公告",
        },
        {
          name: "党建动态",
        },
      ],
      tabsList1: [
        {
          name: "学术成果",
        },
        {
          name: "石窟保护",
        },
        {
          name: "石窟考古",
        },
      ],
      tabsList2: [
        {
          name: "走进龙门",
        },
        {
          name: "研学",
        },
        {
          name: "文创产品",
        },
      ],

      queryParams: {
        cate_id: "14",
        page: 1,
        pagesize: 8,
      },
      queryParams1: {
        cate_id: "29",
        page: 1,
        pagesize: 4,
      },
      queryParams2: {
        cate_id: "18",

        page: 1,
        pagesize: 100,
      },
    };
  },

  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: "首页_龙门石窟研究院_官网",
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  async mounted() {
    await this.getWaizhan();
    this.getArticle();
    this.getXs();
    this.getHd();
    this.getAllLm();
    this.getYx();
    this.getWc();

    this.initSwiper();
  },
  methods: {
    initSwiper() {
      var swiper = new Swiper(".mySwiper", {
        spaceBetween: 15,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: false,
        },
      });
    },
    goOther(item) {
      const params = {
        id: item.id,
      };
      addHit(params).then(() => {
        let url = location.origin;

        // if (item.link != "" && item.link != null) {
        //   window.open(item.link);
        // } else{
        //     window.open(`${url}/#/exhibitDetail?id=${item.id}`);

        // }
        window.open(`${url}/#/exhibitDetail?id=${item.id}`);
      });
    },
    changeTab(item, index) {
      this.current = index;
      if (index == 0) {
        this.queryParams.cate_id = "14";
      } else if (index == 1) {
        this.queryParams.cate_id = "15";
      } else {
        this.queryParams.cate_id = "16";
      }

      this.getArticle();
    },
    changeTab1(item, index) {
      this.current1 = index;
      if (index == 0) {
        this.queryParams1.cate_id = "29";
      } else if (index == 1) {
        this.queryParams1.cate_id = "30";
      } else {
        this.queryParams1.cate_id = "36";
      }
      this.getXs();
    },
    changeTab2(item, index) {
      this.current2 = index;
    },
    // 文章详情
    getArticle() {
      getArticle(this.queryParams).then((res) => [
        (this.artList = res.data.data),
      ]);
    },
    // 陈列外展
    async getWaizhan() {
      const params = {
        cate_id: "44",
        page: 1,
        pagesize: 30,
      };
      await getArticle(params).then((res) => [
        (this.waizhanList = res.data.data),
      ]);
    },

    // 学术成果
    getXs() {
      getArticle(this.queryParams1).then((res) => [
        (this.xsList = res.data.data),
      ]);
    },

    // 互动交流
    getHd() {
      const params = {
        cate_id: "43",
        paeg: 1,
        pagesize: 5,
      };
      getArticle(params).then((res) => [(this.hdList = res.data.data)]);
    },

    getAllLm() {
      // 走进龙门
      const paras0 = {
        cate_id: "17",
        label: "西山石窟",
        page: 1,
        pagesize: 100,
      };
      getArticle(paras0).then((res) => [(this.lmList = res.data.data)]);

      const paras = {
        cate_id: "17",
        label: "东山石窟",
        page: 1,
        pagesize: 100,
      };
      getArticle(paras).then((res) => [(this.lmList1 = res.data.data)]);
      const paras1 = {
        cate_id: "17",
        label: "白园,香山寺",
        page: 1,
        pagesize: 100,
      };
      getArticle(paras1).then((res) => [(this.lmList2 = res.data.data)]);
    },

    getYx() {
      getArticle(this.queryParams2).then((res) => [
        (this.yxList = res.data.data),
      ]);
    },
    getWc() {
      const params = {
        cate_id: "19",
        page: 1,
        pagesize: 3,
      };
      getArticle(params).then((res) => [(this.wcList = res.data.data)]);
    },

    goDetail(item) {
      const params = {
        id: item.id,
      };
      let url = location.origin;
      let tit;

      if (this.current == 0) {
        tit = "工作动态";
      } else if (this.current == 1) {
        tit = "通知公告";
      } else {
        tit = "党建动态";
      }

      addHit(params).then((res) => {
        if (item.link != "" && item.link != null) {
          window.open(item.link);
        } else {
          window.open(`${url}/#/newsDetail?id=${item.id}&tit=${tit}`);
        }
      });
    },

    lookMore() {
      let tit;
      if (this.current == 0) {
        tit = "工作动态";
      } else if (this.current == 1) {
        tit = "通知公告";
      } else if (this.current == 2) {
        tit = "党建动态";
      }

      this.$router.push({
        path: "/newsInfo",
        query: {
          tit,
        },
      });
    },
    lookMore1() {
      let url = location.origin;

      if (this.current1 == 0) {
        // 学术成果
        window.open(`${url}/#/achieInfo`);
      } else if (this.current1 == 1) {
        // 石窟保护
        window.open(`${url}/#/protect`);
      } else {
        // 石窟考古
        window.open(`${url}/#/lmarch`);
      }
    },
    // 互动交流
    goHdJl() {
      let url = location.origin;
      window.open(`${url}/#/exchangeInfo`);
    },
    // 互动交流详情
    gohdjlDetail(item) {
      let url = location.origin;
      const params = {
        id: item.id,
      };
      addHit(params);
      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/exchangeDetail?id=${item.id}`);
      }
    },
    // 石窟保护。石窟考古详情
    goskbhDetail(item) {
      let url = location.origin;
      let tit;
      if (this.current1 == 1) {
        tit = "石窟保护";
      } else if (this.current1 == 2) {
        tit = "石窟考古";
      }
      const params = {
        id: item.id,
      };
      addHit(params);
      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/lmarchDetail?id=${item.id}&tit=${tit}`);
      }
    },

    // 走进龙门详情

    goLmDetail(item) {
      let url = location.origin;
      const params = {
        id: item.id,
      };
      addHit(params);
      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/enterLmDetail?id=${item.id}`);
      }
    },

    // 研学详情

    goYxDetail(item) {
      let url = location.origin;
      const params = {
        id: item.id,
      };
      addHit(params);
      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/studyDetail?id=${item.id}`);
      }
    },
    // 文创详情
    gowcDetail(item) {
      const params = {
        id: item.id,
      };
      addHit(params);
      let url = location.origin;
      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/industriesDetail?id=${item.id}`);
      }
    },

    // 走进龙门，研学，文创 更多
    lookMore2() {
      let url = location.origin;

      let tit;
      if (this.current2 == 0) {
        tit = "走进龙门";
        window.open(`${url}/#/enterLmInfo`);
      } else if (this.current2 == 1) {
        tit = "研学";
        window.open(`${url}/#/studyInfo`);
      } else {
        tit = "文创产品";
        window.open(`${url}/#/industriesInfo`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
._more {
  border: 1px solid #bb945b;
  color: #303030;
  font-size: 14px;
  padding: 8px 14px;
  cursor: pointer;
}

._home {
  width: 100%;
  background-image: url("../assets/image/home-img/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  ._auto {
    max-width: 1280px;
    margin: 0 auto;
    background-color: #fff;
    padding: 37px 20px 35px 20px;
  }
}

._news {
  display: flex;
  justify-content: space-between;

  ._left {
    width: 59.7%;
    cursor: pointer;

    ._imgBox {
      width: 100%;
      height: 383px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        transition: all 0.5s;
        object-fit: cover;
      }

      img:hover {
        transform: scale(1.1);
      }
    }

    h4 {
      color: #303030;
      width: 100%;
      background: #f9f9f9;
      padding: 14px;
      font-size: 22px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  ._right {
    margin-left: 29px;
    width: 38%;

    ._top {
      display: flex;
      border-bottom: 1px solid #d9d9d9;
      align-items: center;
      justify-content: space-between;

      ._tabs {
        ul {
          display: flex;

          li {
            font-size: 20px;
            color: #7a7a7a;
            padding: 15px 24px;
            cursor: pointer;
          }

          ._active {
            background: #f8f5ea;
            border-bottom: 1px solid #a88b61;
            border-radius: 4px 4px 0 0;
            color: #bb945b;
          }
        }
      }
    }

    ._bot {
      margin-top: 15px;

      ul {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          h4 {
            color: #424242;
            font-size: 18px;
            overflow: hidden;
            display: flex;
            align-items: center;

            span {
              color: #bb945b;
              font-size: 34px;
              margin-right: 10px;
            }

            ._p {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 93%;
            }
          }

          ._time {
            font-size: 16px;
            color: #909090;
          }
        }
      }
    }
  }
}

._clList {
  margin-top: 20px;
  height: 230px;
  overflow: hidden;

  ._img {
    height: 182px;
  }
}
.swiper {
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.swiper-slide h4 {
  color: #333;
  font-size: 18px;
  margin-top: 12px;
  margin-bottom: 4px;
}

.swiper-slide p {
  color: #999;
  font-size: 14px;
}

::v-deep .swiper-scrollbar-drag {
  background: #a88b61;
}

._noList {
  margin-top: 40px;
  display: flex;

  ._lf {
    width: 59.7%;

    ._top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f9f9f9;
      border-top: 1px solid #a88b61;

      ._tabs1 {
        ul {
          display: flex;

          li {
            color: #333;
            font-size: 22px;
            padding: 14px 30px;
            cursor: pointer;
          }

          ._active {
            background-color: #a88b61;
            color: white;
          }
        }
      }

      ._more {
        margin-right: 20px;
      }
    }

    ._list {
      margin-top: 10px;
      height: 345px;
      ._li {
        border-bottom: 1px dashed #ebebeb;
        padding: 15px 0;

        h4 {
          color: #333;
          font-size: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        h4::before {
          content: "";
          display: inline-block;
          width: 3px;
          height: 12px;
          background: #d9d9d9;
          margin-left: 5px;
          margin-right: 10px;
        }
        .jjh4::before {
          margin-right: 5px;
        }

        p {
          color: #999999;
          font-size: 18px;
          margin-top: 5px;
          text-indent: 2em;
        }
      }

      ._ll1 {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }
    }

    ._bb {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      img {
        width: 362px;
        cursor: pointer;
      }
    }
  }

  ._rt {
    width: 38%;
    margin-left: 28px;

    ._top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f4f4f4;

      h4 {
        border-radius: 4px 4px 0 0;
        background: #f8f5ea;
        color: #bb945b;
        border-bottom: 1px solid #a88b61;
        padding: 17px 24px;
        font-size: 20px;
      }
    }

    ._rtList {
      margin-top: 19px;

      ._rtLi {
        display: flex;
        margin-bottom: 20px;
        cursor: pointer;
      }

      ._imgBox {
        width: 105px;
        height: 70px;
        overflow: hidden;
        margin-right: 25px;
        border-radius: 5px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s;
        }

        img:hover {
          transform: scale(1.1);
        }
      }

      h4 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* 设置最大显示行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        color: #494949;
        font-size: 18px;
        width: 70%;
        height: 50px;
      }
    }
  }
}

._lmList {
  ._lf {
    ._bo {
      ._lli {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px dashed #e6e6e6;
        padding-bottom: 13px;
        padding-top: 13px;
        cursor: pointer;

        h4 {
          color: #494949;
          font-size: 18px;
          width: 130px;
          margin-right: 18px;
          line-height: 34px;
        }

        h4:nth-child(5n) {
          margin-right: 0;
        }

        h4::before {
          content: "";
          background-image: url("../assets/image/home-img/dian.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          display: inline-block;
          width: 10px;
          height: 10px;
          margin-right: 8px;
          margin-left: 10px;
        }
      }

      ._div1 {
        ._li {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px dashed #f8f5ea;
          padding: 20px 0;
          cursor: pointer;

          h4 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 18px;
          }
          h4::before {
            content: "";
            display: inline-block;
            width: 3px;
            height: 12px;
            background: #d9d9d9;
            margin-left: 5px;
            margin-right: 10px;
          }

          p {
            font-size: 16px;
            color: #909090;
          }
        }
      }

      ._div2 {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        ._li {
          width: 32%;
          cursor: pointer;
          ._img {
            width: 100%;
            height: 130px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: all 0.5s;
            }

            img:hover {
              transform: scale(1.1);
            }
          }

          p {
            font-size: 18px;
            text-align: center;
            padding: 8px 0;
            background-color: #f9f9f9;
          }
        }
      }
    }
  }

  ._qrcode {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;

    ._xian {
      width: 1px;
      height: 110px;
      background: linear-gradient(
        180deg,
        rgba(217, 217, 217, 0) 0%,
        #d9d9d9 50%,
        rgba(217, 217, 217, 0) 100%
      );
      margin: 0 40px;
    }
  }
}
</style>