<template>

    <div class="_wrap">
        <div class="_auto">
            <!-- <myBread name="文创产品" path="/industriesInfo" :child="detail.title" childPath='/industriesDetail'></myBread> -->
      <myBread name="文旅融合" path='/cultural' child="文创产品" childPath='/industriesInfo' :tChild='detail.title' tChildPath='/industriesDetail'></myBread>


            <div class="_content">

                <div class="_l">
                    <img :src="$IMG_URL+detail.cover" alt="">
                </div>
                <div class="_r">
                    <h4>{{detail.title}}</h4>
                    <p>
                        {{detail.descs}}
                    </p>

                    <!-- <div class="_btn">购买></div> -->
                </div>
            </div>
        </div>
        <otherBwg></otherBwg>
    </div>
</template>

<script>
import {getDetail} from '@/api/getData'
export default {
    data(){
        return{
            id:"",
            detail:{}
        }
    },
          // 动态设置页面标题、关键字和描述等属性  
          metaInfo() {

return {
  title: `${this.detail.title}_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
    async mounted(){
        this.id = this.$route.query.id
        await this.getDetail()
        

    },
    methods:{
        async getDetail(){
            const params = {
                id:this.id
            }
            await getDetail(params).then(res=>{
                this.detail = res.data
            })
        }
    }
}
</script>

<style lang="scss" scoped>
._content
{
    margin: 0 20px;
    display: flex;
    padding-bottom: 100px;

    ._l
    {
        width: 486px;
        height: 310px;
        overflow: hidden;
        border: 1px solid #ccc;
        img
        {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: all .5s;
        }

        img:hover
        {
            transform: scale(1.1);
        }
    }

    ._r
    {
        flex: 1;
        margin-left: 20px;

        h4
        {
            font-size: 24px;
            color: #333;
            font-weight: bold;
            margin-bottom: 27px;
            margin-top: 7px;
        }

        p
        {
            font-size: 18px;
            text-indent: 2em;
            line-height: 32px;
            height: 130px;
            overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              /* 设置最大显示行数 */
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
        }

        ._btn
        {
            width: 122px;
            height: 42px;
            border-radius: 6px;
            border: 1px solid #A88B61;
            color: #A88B61;
            text-align: center;
            line-height: 42px;
            margin-top: 70px;
            cursor: pointer;

        }
    }
}
</style>