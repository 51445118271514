export const listMenu = [

    {

        title: "首页",
        id: "/",
    },
    {

        title: "机构概况",
        id: "/insPro",
        child: [
            {
                subtit: "领导班子"
            },
            {
                subtit: "职能简介"
            }, {
                subtit: "机构设置"
            }, {
                subtit: "机构沿革"
            },
        ]
    },
    {

        title: "新闻资讯",
        id: "/newsInfo",
        child: [
            {
                subtit: "工作动态",
            },
            {
                subtit: "通知公告",
            }, {
                subtit: "党建动态",
            }
        ]
    },
    {

        title: "安全保卫",
        id: "/security",
        child: [
            {
                subtit: "三防措施"
            },
            {
                subtit: "安全工作"
            }, {
                subtit: "学习培训、演练"
            }, {
                subtit: "安防"
            }, {
                subtit: "旅游高峰期"
            }, {
                subtit: "助人为乐"
            }
        ]
    },
    {

        title: "保护研究",
        id: "/research",
        child: [
            {
                subtit: "学习成果",
                subId: "/achieInfo"
            },
            {
                subtit: "石窟保护",
                subId: "/protect"
            }, {
                subtit: "石窟艺术"
            }, {
                subtit: "石窟考古",
                subId: "/lmarch"
            }, {
                subtit: "数字龙门",
                subId: "/diglm"
            }
        ]
    },
    {

        title: "互动交流",
        id: "/exchange",
        child: [
            {
                subtit: "交流合作",
                subId: "/exchangeInfo"
            },
            {
                subtit: "陈列外展",
                subId: "/exhibitInfo"
            }, {
                subtit: "院藏展品",
                subId: "/concealInfo"
            },
        ]
    },
    {

        title: "文旅融合",
        id: "/cultural",
        child: [
            {
                subtit: "走进龙门",
                subId: "/enterLmInfo",
            },
            {
                subtit: "研学",
                subId: "/studyInfo",
            }, {
                subtit: "文创产品",
                subId: "/industriesInfo"
            },
        ]
    },
    {

        title: "石窟专委会",
        id: "/committee",
        child: [
            {
                subtit: "机构设置"
            },
            {
                subtit: "石窟寺研究",
                subId: "/sksInfo"
            }, {
                subtit: "专题活动",
                subId: "/meetInfo?tit=专题活动"
            }, {
                subtit: "历届年会",
                subId: "/meetInfo?tit=历届年会"
            },
        ]
    },
]