import { render, staticRenderFns } from "./lmarchDetail.vue?vue&type=template&id=5363c92c&scoped=true"
import script from "./lmarchDetail.vue?vue&type=script&lang=js"
export * from "./lmarchDetail.vue?vue&type=script&lang=js"
import style0 from "./lmarchDetail.vue?vue&type=style&index=0&id=5363c92c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5363c92c",
  null
  
)

export default component.exports