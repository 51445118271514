<template>
    <div class="_wrap">
        <div class="_auto">
            <myBread name="保护研究" path='/research' child="石窟保护" childPath='/protect'></myBread>


            <div class="_qt">
                <div class="_top">
                    <div class="_tabs1">
                        <ul>
                            <li :class="{ _active: current1 == index }" v-for="(item, index) in tabsList1" :key="index"
                                @click="changeTab1(item, index)">{{
                                item.name }}</li>
                        </ul>
                    </div>


                    <div class="_list">
                        <div class="_li" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
                            <div class="_img">
                                <img :src="$IMG_URL + item.cover" alt="">
                            </div>
                            <div class="_bb">
                                <h4>{{ item.title }}</h4>
                                <p class="_sub">
                                    {{ item.descs }}
                                </p>
                                <p>时间：{{ item.pub_time }}</p>
                            </div>
                        </div>



                    </div>
                    <div class="_page">
                        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page"
                            :limit.sync="queryParams.pagesize" @pagination="getList" />

                    </div>
                </div>
            </div>
        </div>

        <otherBwg></otherBwg>
    </div>
</template>

<script>
import { getArticle,addHit } from '@/api/getData'
export default {
    data() {
        return {
            list: [],
            current1: 0,
            total: 0,
            queryParams: {
                cate_id: "31",
                page: 1,
                pagesize: 4,
            },
            tabsList1: [
                {
                    name: "保护工程"
                }, {
                    name: "项目保护"
                }, {
                    name: "日常维护"
                },
                {
                    name: "石窟监测"
                }
            ],
        }
    },

          // 动态设置页面标题、关键字和描述等属性  
          metaInfo() {

return {
  title: `石窟保护_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},

    async mounted() {
        let params = this.$route.query.current
        if (params) {
            this.current1 = params
        } else {
            this.current1 = 0
        }
        if (this.current1 == 0) {
            this.queryParams.cate_id = '31'
        } else if (this.current1 == 1) {
            this.queryParams.cate_id = '32'

        } else if (this.current1 == 2) {
            this.queryParams.cate_id = '33'

        } else if (this.current1 == 3) {

            this.queryParams.cate_id = '34'
        }

        await this.getList()

 
    },
    methods: {
        changeTab1(item, index) {
            this.current1 = index
            if (index == 0) {
                this.queryParams.cate_id = '31'
            } else if (index == 1) {
                this.queryParams.cate_id = '32'

            } else if (index == 2) {
                this.queryParams.cate_id = '33'

            } else if (index == 3) {

                this.queryParams.cate_id = '34'
            }

            this.queryParams.page = 1
            this.getList()
        },
        async getList() {
            await getArticle(this.queryParams).then(res => {
                console.log(res)
                this.list = res.data.data
                this.total = res.data.total
            })
        },
        goDetail(item){
            let url = location.origin
            let tit  = this.tabsList1[this.current1].name

            
            const params = {
                id:item.id
            }
            addHit(params)
            if(item.link!='' && item.link!=null){
                window.open(item.link)
            }else{
                window.open(`${url}/#/protectDetail?id=${item.id}&tit=${tit}`)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
._page
{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

._qt
{
    margin-top: 40px;

    ._top
    {

        border-top: 1px solid #A88B61;
        margin: 0 20px;

        ._tabs1
        {
            background: #F9F9F9;

            ul
            {
                display: flex;

                li
                {
                    color: #333;
                    font-size: 22px;
                    padding: 14px 30px;
                    cursor: pointer;
                }

                ._active
                {
                    background-color: #A88B61;
                    color: white;
                }
            }
        }

        ._more
        {
            margin-right: 20px;
        }
    }

    ._list
    {

        ._li
        {
            cursor: pointer;
            padding: 20px 20px 20px 0;
            display: flex;
            border-bottom: 1px dashed #E2E2E2;
            align-items: center;
            ._img
            {
                overflow: hidden;
                width: 234px;
                height: 145px;

                img
                {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all .5s;

                }

                img:hover
                {
                    transform: scale(1.1);
                }
            }

            ._bb
            {
                flex: 1;
                margin-left: 30px;

                ._sub
                {
                    line-height: 26px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    /* 设置最大显示行数 */
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    color: #888;
                }
            }

            h4
            {
                font-size: 20px;
                color: #333;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

            }

            p
            {
                font-size: 16px;
                color: #666;
                margin-top: 10px;
            }

        }

        ._li:nth-child(3n)
        {
            margin-right: 0;
        }
    }
}
</style>