<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread
        name="保护研究"
        path="/research"
        child="学术成果"
        childPath="/achieInfo"
      ></myBread>

      <div class="_list">
        <div class="_th">
          <p class="_n">
            <span>成果名称</span>
          </p>
          <p class="_type">类型</p>
          <p class="_a">作者</p>
          <p class="_y">出版时间(年)</p>
          <p class="_k">开本</p>
        </div>

        <div class="_td">
          <div class="_tr" v-for="(item, index) in list" :key="index">
            <!-- <div class="_img">
                            <img :src="$IMG_URL+item.cover" alt="">
                        </div> -->
            <h4>{{ item.title }}</h4>
            <p class="_l">{{ item.types }}</p>
            <p class="_z">{{ item.author_name }}</p>
            <p class="_c">{{ item.publish_time }}</p>
            <p class="_k">{{ item.open_book }}</p>
          </div>

          <div class="_page">
            <pagination
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.page"
              :limit.sync="queryParams.pagesize"
              @pagination="getList"
            />
          </div>
        </div>
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle } from "@/api/getData";
export default {
  data() {
    return {
      list: [],
      total: 0,
      queryParams: {
        cate_id: "29",
        page: 1,
        pagesize: 10,
      },
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: `学术成果_龙门石窟研究院_官网`,
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  async mounted() {
    await this.getList();

  },
  methods: {
    async getList() {
      await getArticle(this.queryParams).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
._page {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

._list {
  margin: 0 20px;

  ._th {
    display: flex;
    background-color: #f9f9f9;
    align-items: center;
    padding: 15px 0;

    p {
      font-size: 22px;
      color: #a88b61;
    }

    ._n {
      width: 750px;

      span {
        margin-left: 150px;
      }
    }

    ._type {
      width: 200px;
    }

    ._a {
      width: 200px;
    }

    ._y {
      width: 300px;
    }

    ._k {
      width: 100px;
    }
  }

  ._td {
    ._tr {
      display: flex;
      align-items: center;
      border-bottom: 1px dashed #ebebeb;
      padding: 20px 0;

      ._img {
        width: 44px;
        height: 63px;
        margin-left: 30px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      h4 {
        width: 600px;
        font-size: 20px;
        color: #333;
        // margin-left: 70px;
      }

      p {
        color: #666666;
        font-size: 18px;
      }

      ._l {
        // margin-left: 60px;
        width: 90px;
      }

      ._z {
        // margin-left: 170px;
        width: 170px;
        margin-left: 70px;
      }

      ._c {
        margin-left: 30px;
        width: 100px;
      }

      ._k {
        margin-left: 95px;
        width: 70px;
      }
    }
  }
}
</style>