<template>
    <div class="_other">
        <!-- <img src="../../assets/image/home-img/image 711.png" alt="龙门石窟研究院官网">
        <img src="../../assets/image/home-img/image 713.png" alt="龙门石窟研究院官网">
        <img src="../../assets/image/home-img/image 712.png" alt="龙门石窟研究院官网">
        <img src="../../assets/image/home-img/image 714.png" alt="龙门石窟研究院官网">
        <img src="../../assets/image/home-img/image 715.png" alt="龙门石窟研究院官网">
        <img src="../../assets/image/home-img/image 716.png" alt="龙门石窟研究院官网"> -->

        <img @click="goOther(item)" v-for="(item, index) in linkArr" :key="index" :src="$IMG_URL + item.logo"
            :alt="item.title">

    </div>
</template>

<script>
import { getLink } from '@/api/getData'
export default {
    data() {
        return {
            linkArr: []
        }
    },
    created() {
        this.getLink()
    },
    methods: {
        getLink() {
            getLink().then(res => {
                // console.log(res)
                this.linkArr = res.data
            })
        },
        goOther(item) {
            window.open(item.link)
        }
    }
}
</script>

<style lang="scss" scoped>
._other
{
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 37px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;

    img
    {
        width: 208px;
        height: 69px;
        object-fit: cover;
        cursor: pointer;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    img:hover
    {
        -ms-transform: translateY(-10px);
        -webkit-transform: translateY(-10px);
        -moz-transform: translateY(-10px);
        -o-transform: translateY(-10px);
        transform: translateY(-10px);
        z-index: 1002;
        position: relative;
    }
}
</style>