<template>
  <div class="_wrap">
    <div class="_banner">
      <img src="../../assets/image/research-img/banner.png" alt="" />
    </div>

    <div class="_first s1">
      <div class="_bg23">
        <img src="../../assets/image/research-img/bg23.png" alt="" />
      </div>
      <div class="_f_a">
        <div class="_left">
          <div class="_tit">
            <img src="../../assets/image/research-img/t15.png" alt="" />
            <h4>数字档案</h4>
          </div>
        </div>

        <div class="_right">
          <p class="_js">
            建立高精度、永久性、科学全面的涵盖龙门东西山石窟、院藏文物和拓本、流散海内外文物、龙门石窟保护研究史料等各方面的数字档案是龙门石窟数字化保护工作的首要任务。
          </p>

          <div class="swiper mySwiper">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in szdaList"
                :key="index"
              >
                <div class="_f_img">
                  <img :src="$IMG_URL + item.cover" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
    </div>

    <div class="_two s2">
      <div class="_f_a">
        <div class="_tit">
          <img
            src="../../assets/image/research-img/t16.png"
            alt=""
            class="t1"
          />
          <img
            src="../../assets/image/research-img/t4-2.png"
            alt=""
            class="t2"
          />

          <h4>虚拟复原</h4>
        </div>

        <div class="_t_cont" v-if="xnfy_img.length > 0">
          <div class="_left">
            <div class="_img">
              <img :src="$IMG_URL + xnfy_img[0]" alt="" />
            </div>

            <div class="_txt">
              <div class="_zs">
                <img src="../../assets/image/research-img/h29a.png" alt="" />
              </div>
              <p>{{ xnfyList.title }}</p>
            </div>
          </div>
          <div class="_mid">
            {{ xnfyList.descs }}
          </div>
          <div class="_left _right">
            <div class="_img">
              <img :src="$IMG_URL + xnfy_img[1]" alt="" />
            </div>

            <div class="_txt">
              <div class="_zs">
                <img src="../../assets/image/research-img/h29a.png" alt="" />
              </div>
              <p>{{ xnfyList.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="_first _three s3">
      <div class="_f_a">
        <div class="_left">
          <div class="_tit">
            <img
              src="../../assets/image/research-img/t17.png"
              alt=""
              class="_t17"
            />
            <img
              src="../../assets/image/research-img/t17-2.png"
              alt=""
              class="_t17-2"
            />
            <h4>微痕识别</h4>
          </div>
        </div>

        <div class="_right">
          <p class="_js">
            龙门石窟碑刻题记多达2800余块，凡30余万字，居世界石窟之首。因质地细腻的石灰岩岩质，龙门石窟拥有大量精美浅浮雕图案。因自然风化等原因，很多碑刻题记及图案纹饰已模糊不清。借助微痕识别技术，使模糊的图案文字清晰化、所蕴含的丰富的文物信息还原再现，助力文物保护、考古研究、展示利用等工作。
          </p>

          <div class="swiper mySwiper1">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in whsbList"
                :key="index"
              >
                <div class="_img">
                  <img :src="$IMG_URL + item.cover" alt="" />
                </div>
                <div class="_tit">
                  <p>{{ item.title }}</p>
                  <img src="../../assets/image/research-img/t13.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
    </div>

    <div class="_first _four s4">
      <div class="_f_a">
        <div class="_left">
          <div class="_tit">
            <img src="../../assets/image/research-img/t18.png" alt="" />
            <h4>数字展示</h4>
          </div>
        </div>

        <div class="_right">
          <div class="swiper mySwiper2">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in szzsList"
                :key="index"
                @click="goszZs(item)"
              >
                <div class="_ou_img">
                  <img :src="$IMG_URL + item.cover" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>

          <div class="_f_des">
            <p>
              借助虚拟现实、增强现实、3D打印、人工智能等多种技术手段，既可以成功实现洞窟内部虚拟漫游，又能使高悬在龙门山崖壁上不可移动的窟龛造像“活”起来、“走”出去，实现其传播传统文化的“移动”之旅。
            </p>

            <img src="../../assets/image/research-img/h86.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="_five s5">
      <div class="_f_a">
        <div class="_tit">
          <h4>视频展示</h4>
          <img
            src="../../assets/image/research-img/t19.png"
            alt=""
            class="_ll1"
          />
          <img
            src="../../assets/image/research-img/t17-2.png"
            alt=""
            class="_ll2"
          />
        </div>

        <div class="_list">
          <div class="swiper mySwiper3">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in spzsList"
                :key="index"
                @click="lookVideo(item)"
              >
                <div class="_img">
                  <img :src="$IMG_URL + item.cover" alt="" class="_cover" />
                  <img
                    src="../../assets/image/research-img/play.png"
                    alt=""
                    class="_bf"
                  />
                </div>

                <div class="_sub">
                  <div class="_l">
                    <p class="_ri">{{ item.pub_time.substring(8) }}</p>
                    <div class="_time">
                      <p>{{ item.pub_time.substring(0, 4) }}</p>
                      <p>{{ item.pub_time.substring(5, 7) }}</p>
                    </div>
                  </div>

                  <div class="_r">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>

          <div class="x_more" @click="goMore">
            <div class="_icon">
              <img src="../../assets/image/research-img/more.png" alt="" />
            </div>
            <div class="_txt" >查看更多</div>
          </div>
        </div>
      </div>
    </div>

    <videoBox ref="videoBoxRef"></videoBox>
  </div>
</template>

<script>
import Swiper from "swiper";
import { getArticle, addHit } from "@/api/getData";
import videoBox from "@/components/diaVideo/dieVideo.vue";
export default {
  components: {
    videoBox,
  },
  data() {
    return {
      szdaList: [],
      xnfyList: {},
      xnfy_img: [],
      whsbList: [],
      szzsList: [],
      spzsList: [],
      queryParams: {
        cate_id: "41",
        page: 1,
        pagesize: 100,
      },
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: `数字龙门_龙门石窟研究院_官网`,
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  async mounted() {
    await this.getData();
    this.initSwiper();
    this.initSwiper1();
    this.initSwiper2();
    this.initSwiper3();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 平滑滚动
    });


  },
  methods: {
    goszZs(item) {
      let url = location.origin;
      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/numDetail?id=${item.id}`);
      }
    },
    goMore() {
      let url = location.origin;
      window.open(`${url}/#/videoInfo?tit=视频展示`);
    },
    lookVideo(item) {
      let url = location.origin;
      const params = {
        id: item.id,
      };
      addHit(params);
      this.$refs.videoBoxRef.edit(item);
    },
    initSwiper() {
      var swiper = new Swiper(".mySwiper", {
        loop: true,
        navigation: {
          nextEl: ".s1 .swiper-button-next",
          prevEl: ".s1 .swiper-button-prev",
        },
      });
    },

    initSwiper1() {
      var swiper = new Swiper(".mySwiper1", {
        loop: true,
        navigation: {
          nextEl: ".s3 .swiper-button-next",
          prevEl: ".s3 .swiper-button-prev",
        },
      });
    },

    initSwiper2() {
      var swiper = new Swiper(".mySwiper2", {
        navigation: {
          nextEl: ".s4 .swiper-button-next",
          prevEl: ".s4 .swiper-button-prev",
        },
      });
    },

    initSwiper3() {
      var swiper = new Swiper(".mySwiper3", {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".s5 .swiper-button-next",
          prevEl: ".s5 .swiper-button-prev",
        },
      });
    },

    async getData() {
      const params = {
        cate_id: "38", //数字档案
        page: 1,
        pagesize: 50,
      };
      await getArticle(params).then((res) => {
        this.szdaList = res.data.data;
      });

      const params1 = {
        cate_id: "39", //虚拟复原
        page: 1,
        pagesize: 1,
      };
      await getArticle(params1).then((res) => {
        this.xnfyList = res.data.data[0];
        this.xnfy_img = this.xnfyList.tuji.split(",");
      });

      const params2 = {
        cate_id: "40", //微狠识别
        page: 1,
        pagesize: 50,
      };
      await getArticle(params2).then((res) => {
        this.whsbList = res.data.data;
      });

      const params3 = {
        cate_id: "41", //数字展示
        page: 1,
        pagesize: 50,
      };

      await getArticle(params3).then((res) => {
        this.szzsList = res.data.data;
      });

      const params4 = {
        cate_id: "42", //视频展示
        page: 1,
        pagesize: 6,
      };

      await getArticle(params4).then((res) => {
        this.spzsList = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
._banner {
  width: 100%;
  img {
    width: 100%;
  }
}
._f_a {
  max-width: 1280px;
  margin: 0 auto;
}
._first {
  background-image: url("../../assets/image/research-img/bg5.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 160px 0;
  position: relative;

  ._bg23 {
    position: absolute;
    bottom: 70px;
    left: 230px;
  }
  ._f_a {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }
  ._left {
    ._tit {
      position: relative;
      img {
        position: absolute;
        bottom: -160px;
      }
    }
    h4 {
      font-size: 56px;
      letter-spacing: 12px;
      word-break: break-all;
      line-height: 1.2;
      writing-mode: vertical-lr;
    }
  }

  ._right {
    display: flex;
    position: relative;
    ._js {
      font-size: 16px;
      line-height: 1.9;
      text-align: justify;
      writing-mode: vertical-rl;
      margin-right: 90px;
      height: 370px;
    }

    .mySwiper {
      position: relative;
      width: 840px;
      height: 536px;
      overflow: hidden;
      z-index: 100;

      ._f_img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          transition: all 0.5s;
        }
        img:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

._two {
  background-image: url("../../assets/image/research-img/bg24.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  ._f_a {
    padding: 155px 0;
  }
  ._tit {
    position: relative;
    width: 100px;
    margin: 0 auto;
    h4 {
      letter-spacing: 12px;
      word-break: break-all;
      font-size: 56px;
      line-height: 1.2;
      writing-mode: vertical-lr;
    }

    .t1 {
      position: absolute;
      left: -40px;
      top: -80px;
    }
    .t2 {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  ._t_cont {
    margin-top: 104px;
    display: flex;
    justify-content: space-between;
    ._left {
      width: 500px;
      height: 800px;
      position: relative;
      ._img {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }

      ._txt {
        position: absolute;
        width: 120px;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        padding: 154px 0 0 38px;
        z-index: 2;

        p {
          font-size: 20px;
          word-break: break-all;
          line-height: 1.5;
          writing-mode: vertical-lr;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        ._zs {
          position: relative;
        }

        img {
          position: absolute;
          right: 30px;
          top: -30px;
          left: auto;
        }
      }
    }

    ._mid {
      font-size: 16px;
      height: 400px;
      line-height: 1.9;
      writing-mode: vertical-lr;
    }

    ._right {
      ._txt {
        right: 0;
      }
    }
  }
}

._three {
  background-image: url("../../assets/image/research-img/bg25.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  ._left {
    ._tit {
      ._t17 {
        top: -40px;
        left: -35px;
      }
      ._t17-2 {
        bottom: 0;
        right: -35px;
      }
    }
  }

  ._right {
    .mySwiper1 {
      position: relative;
      width: 810px;
      height: 447px;
      overflow: hidden;
      z-index: 100;

      .swiper-slide {
        display: flex;
      }

      ._img {
        width: 711px;
        height: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          transition: all 0.5s;
        }
        img:hover {
          transform: scale(1.1);
        }
      }

      ._tit {
        padding-left: 27px;
        padding-top: 31px;
        position: relative;
        img {
          position: absolute;
          right: -10px;
          top: -0;
        }
        p {
          writing-mode: vertical-lr;
          font-size: 20px;
        }
      }
    }
  }
}

._four {
  background-image: url("../../assets/image/research-img/bg26.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  ._left {
    ._tit {
      h4 {
        color: white;
      }
      img {
        bottom: -170px;
      }
    }
  }

  ._right {
    margin-left: 85px;
    .mySwiper2 {
      position: relative;
      width: 961px;
      height: 536px;
      overflow: hidden;
      z-index: 100;

      ._ou_img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s;
        }
        img:hover {
          transform: scale(1.1);
        }
      }
    }

    ._f_des {
      height: 520px;
      padding-left: 80px;
      padding-top: 28px;
      position: relative;
      p {
        font-size: 16px;
        text-align: justify;
        line-height: 1.9;
        writing-mode: vertical-rl;
        color: white;
      }

      img {
        position: absolute;
        top: 0;
        left: 60px;
      }
    }
  }
}

._five {
  background-image: url("../../assets/image/research-img/bg4.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  padding: 204px 0 468px;

  ._tit {
    position: relative;
    width: 100px;
    margin: 0 auto;
    h4 {
      font-size: 56px;
      line-height: 1.2;
      word-break: break-all;
      writing-mode: vertical-lr;
      letter-spacing: 12px;
    }
    ._ll1 {
      position: absolute;
      left: -38px;
      top: -60px;
    }
    ._ll2 {
      position: absolute;
      right: 0;
      bottom: 5px;
    }
  }

  ._list {
    position: relative;
  }

  .mySwiper3 {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: 100px;
    .swiper-slide {
      ._img {
        width: 100%;
        height: 256px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        ._cover {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s;
        }
        ._cover:hover {
          transform: scale(1.1);
        }

        ._bf {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -24px;
          margin-top: -24px;
        }
      }
      ._sub {
        display: flex;
        align-items: center;
        margin-top: 20px;
        ._l {
          display: flex;
          align-items: center;
          ._ri {
            font-size: 40px;
            padding-right: 12px;
            position: relative;
          }
          ._ri::after {
            content: "";
            border-right: 2px solid #e2d6b8;
            position: absolute;
            right: 0;
            top: 10px;
            height: 35px;
          }
          ._time {
            margin-left: 12px;
            color: #333;
          }
        }

        ._r {
          font-size: 20px;
          margin-left: 15px;
        }
      }
    }
  }

  .x_more {
    position: absolute;
    bottom: -330px;
    left: 50%;
    margin-left: -15px;
    text-align: center;
    cursor: pointer;
    ._txt {
      width: 30px;
      margin-top: 10px;
      writing-mode: vertical-rl;
      font-size: 20px;
      text-align: center;
    }
  }
}

._first .swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "";
  display: inline-block;
  width: 29px;
  height: 52px;
  background-image: url("../../assets/image/research-img/prev1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
._first .swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "";
  display: inline-block;
  width: 29px;
  height: 52px;
  background-image: url("../../assets/image/research-img/next1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

._first .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 0;
  top: auto;
  bottom: 10px;
}
._first .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  left: 100px;
  top: auto;
  bottom: 10px;
}

._three .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 80px;
  top: auto;
  bottom: 0;
}

._three .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  left: 180px;
  top: auto;
  bottom: 0;
}

._four .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: -170px;
  top: auto;
  bottom: 0;
}

._four .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  left: -80px;
  top: auto;
  bottom: 0;
}

._five .swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "";
  display: inline-block;
  width: 29px;
  height: 52px;
  background-image: url("../../assets/image/research-img/prev1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

._five .swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "";
  display: inline-block;
  width: 29px;
  height: 52px;
  background-image: url("../../assets/image/research-img/next1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

._five .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  top: auto;
  bottom: -100px;
  left: 42%;
}

._five .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  top: auto;
  bottom: -100px;
  right: 42%;
}
</style>