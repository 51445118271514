import axios from "@/axios/until";

/**
 * 查询首页数据
 */
export function getHomeData(data) {
  return axios({
    url: '/home',
    method: 'post',
    data: data
  })
}

/**
 * 网站数据
 */
export function getweb_data(data) {
  return axios({
    url: '/Conf',
    method: 'post',
    data: data
  })
}

/**
 * 友情链接
 * Friend/links
 */
export function getLink(data) {
  return axios({
    url: '/Friend/links',
    method: 'post',
    data: data
  })
}

/**
 * 文章列表
 */
export function getArticle(data) {
  return axios({
    url: '/Article',
    method: 'post',
    data: data
  })
}
/**
 * 文章详情
 */
export function getDetail(data) {
  return axios({
    url: '/Article/detial',
    method: 'post',
    data: data
  })
}

/**
 * 增加点击量
 * /Article/click
 */
export function addHit(data) {
  return axios({
    url: '/Article/click',
    method: 'post',
    data: data
  })
}

/**
 * 院藏列表
 */
export function getRelic(data) {
  return axios({
    url: '/Relic',
    method: 'post',
    data: data
  })
}
/**
 * 院藏详情
 */

 export function getRelicDetail(data) {
  return axios({
    url: '/Relic/detial',
    method: 'post',
    data: data
  })
}

/**
 * 藏品点击量 
 */

export function reHit(data) {
  return axios({
    url: '/Relic/click',
    method: 'post',
    data: data
  })
}

/**
 * 机构设置
 * 
 */

export function institution(data) {
  return axios({
    url: '/Committee/institution',
    method: 'post',
    data: data
  })
}

/**
 * 搜索 
 */
export function getSearch(data) {
  return axios({
    url: '/Article/search',
    method: 'post',
    data: data
  })
}

/**
 * 机构设置
 */
export function getOrganization(data) {
  return axios({
    url: '/Article/organization',
    method: 'post',
    data: data
  })
}

/**
 * 藏品检索下拉框 
 */

export function getParams(data) {
  return axios({
    url: '/Relic/params',
    method: 'post',
    data: data
  })
}






