<template>
  <div class="_wrap">
    <div class="_auto">
      <!-- <myBread name="院藏展品" path='/concealInfo' :child="detail.title" childPath='/concealDetail'></myBread> -->
      <myBread name="互动交流" path='/exchange' child="院藏展品" childPath='/concealInfo' :tChild='detail.title' tChildPath='/concealDetail'></myBread>


      <div class="_cont">
        <div class="_left">
          <!-- <el-image style="width: 100%; height: 100%" :src="$IMG_URL + detail.file"
                        :preview-src-list="srcList">
                    </el-image> -->
          <img :src="$IMG_URL + detail.file" alt="" />
        </div>
        <div class="_right">
          <div class="_xian"></div>

          <div class="_ccc">
            <h4>{{ detail.title }}</h4>

            <div class="_sub">
              <p>
                <span class="_sp1">朝代：</span>
                <span>{{ detail.material }}</span>
              </p>
              <p>
                <span class="_sp1">材质：</span>
                <span>{{ detail.year }}</span>
              </p>
              <p>
                <span class="_sp1">尺寸：</span>
                <span>{{ detail.size }}</span>
              </p>
            </div>

            <div class="_ins _sub">
              <span class="_sp1">来源说明：</span>
              <span>{{ detail.from_note }}</span>
            </div>

            <div class="_btn">
              <div class="_bt1" @click="look">查看大图</div>
              <div class="_bt2" @click="downLoad">下载</div>
            </div>
          </div>
        </div>
      </div>

      <div class="_top">
        <h4>藏品推荐</h4>
        <!-- <div class="_more" @click="goBh">MORE</div> -->
      </div>

      <div class="_ycList">
        <div
          class="_li"
          v-for="(item, index) in detail.recommend"
          :key="index"
          @click="goCpDetail(item)"
        >
        <div class="_img">
          <img :src="$IMG_URL + item.file" alt="" />
          <p>{{ item.title }}</p>
            
        </div>
        </div>
      </div>
    </div>

    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="srcList"
    />
  </div>
</template>

<script>
import { getRelicDetail } from "@/api/getData";
// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: {
    ElImageViewer,
  },
  data() {
    return {
      showViewer: false, // 显示查看器
      id: "",
      detail: {},
      srcList: [],
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `${this.detail.title}_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  async mounted() {
    this.id = this.$route.query.id;
    await this.getDetail();


  },
  methods: {
    async getDetail() {
      const params = {
        id: this.id,
      };
      await getRelicDetail(params).then((res) => {
        this.detail = res.data;
        this.srcList.push(this.$IMG_URL + this.detail.file);
      });
    },
    look() {
      this.showViewer = true;
    },

    goCpDetail(item) {
      let url = location.origin;

      window.open(`${url}/#/concealDetail?id=${item.id}`);
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    // 下载
    downImg(imgUrl) {
      var img = imgUrl;
      var alink = document.createElement("a");
      alink.href = img;
      console.log(alink);
      alink.download = this.detail.title;
      alink.click();
      document.body.removeChild(link);
    },

    downLoad() {
      this.$confirm("确定要下载此图片吗", "消息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.downImg(this.$IMG_URL + this.detail.file);
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
._cont {
  margin: 0 20px;
  display: flex;
  background-color: #f9f9f9;
  padding: 30px;
  margin-bottom: 50px;

  ._left {
    width: 528px;
    height: 372px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ._right {
    display: flex;
    margin-left: 50px;

    width: 50%;

    ._xian {
      height: 310px;
      margin-top: 30px;
      border-left: 1px solid #ece8e8;
      margin-right: 40px;
    }

    ._ccc {
      > h4 {
        color: #333;
        font-size: 26px;
        margin-bottom: 40px;
      }

      > h4::before {
        content: "";
        display: inline-block;
        width: 2px;
        height: 20px;
        background: #a88b61;
        margin-right: 12px;
      }

      ._sub {
        p {
          line-height: 28px;
        }

        span {
          color: #666;
          font-size: 18px;
        }

        ._sp1 {
          font-weight: bold;
          color: #333;
        }
      }

      ._ins {
        margin-top: 30px;
      }

      ._btn {
        display: flex;
        margin-top: 70px;

        > div {
          width: 147px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          cursor: pointer;
        }

        ._bt1 {
          color: #a88b61;
          border: 1px solid #a88b61;
        }

        ._bt2 {
          color: #666666;
          border: 1px solid #cccccc;
          margin-left: 10px;
        }
      }
    }
  }
}

._more {
  border: 1px solid #bb945b;
  color: #303030;
  font-size: 14px;
  padding: 8px 14px;
  cursor: pointer;
}

._top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
  margin: 0 20px;

  h4 {
    border-radius: 4px 4px 0 0;
    background: #f8f5ea;
    color: #bb945b;
    border-bottom: 1px solid #a88b61;
    padding: 17px 24px;
    font-size: 20px;
  }
}

._ycList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px;
  margin-top: 23px;

  ._li {
    margin-right: 15px;
    margin-bottom: 20px;
    cursor: pointer;
    ._img {
      width: 400px;
      height: 300px;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.5s;
      }

      img:hover {
        transform: scale(1.1);
      }
    }

    p {
      font-size: 22px;
      color: #333;
      text-align: center;
      position: absolute;
      bottom: 20px;
      width: 100%;
      text-align: center;
    }
  }

  ._li:nth-child(3n) {
    margin-right: 0;
  }
}
</style>