let BASE_API,IMG_URL
if (process.env.NODE_ENV === "development") {

    /**
     * 开发环境
     */

	BASE_API = "https://www.lmskyjy.com/api"
	// BASE_API = "http://192.168.0.10:8066/api"


    // IMG_URL = "http://xin.lmskyjy.com"
    IMG_URL = "https://www.lmskyjy.com"

	
} else {


    /**
     * 生产环境
     */
    BASE_API = "https://www.lmskyjy.com/api" //生产环境api
    IMG_URL = "https://www.lmskyjy.com"
    

}
export { BASE_API,IMG_URL}