<template>
  <div class="_wrap">
    <div class="_auto">
      <div class="_qt">
        <div class="_top">
          <div class="_tabs1">
            <ul>
              <li>搜索结果</li>
            </ul>
          </div>

          <div class="_list">
            <div
              class="_li"
              v-for="(item, index) in list"
              :key="index"
              @click="goDetail(item)"
            >
              <!-- <div class="_img">
                <img :src="$IMG_URL + item.cover" alt="" />
              </div> -->

              <div class="_bb">
                <h4 v-html="item.title"></h4>
                <p class="_sub" v-html="item.descs"></p>
                <p class="xq">查看详情 --></p>
              </div>
            </div>
          </div>
          <div class="_page">
            <pagination
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.page"
              :limit.sync="queryParams.pagesize"
              @pagination="getList"
            />
          </div>
        </div>
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getSearch, addHit } from "@/api/getData";
export default {
  data() {
    return {
      list: [],
      total: 0,
      queryParams: {
        page: 1,
        pagesize: 10,
        keywords: "",
      },
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: `搜索结果_龙门石窟研究院_官网`,
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  mounted() {
    let key = this.$route.query.key;
    this.queryParams.keywords = key;
    this.getList();
  },
  methods: {
    goDetail(item) {
      const params = {
        id: item.id,
      };
      addHit(params);
      let url = location.origin;

      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(
          `${url}/#/searchDetail?id=${item.id}&key=${this.queryParams.keywords}`
        );
      }
    },
    getList() {
      getSearch(this.queryParams).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },


  },
};
</script>

<style lang="scss" scoped>
._page {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

._qt {
  margin-top: 20px;

  ._top {
    border-top: 1px solid #a88b61;
    margin: 0 20px;

    ._tabs1 {
      background: #f9f9f9;

      ul {
        display: flex;

        li {
          color: #333;
          font-size: 22px;
          padding: 14px 30px;
          background-color: #a88b61;
          color: white;
        }
      }
    }

    ._more {
      margin-right: 20px;
    }
  }

  ._list {
    ._li {
      cursor: pointer;
      padding: 20px 20px 20px 0;
      display: flex;
      border-bottom: 1px dashed #e2e2e2;

      ._img {
        overflow: hidden;
        width: 234px;
        height: 145px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s;
        }

        img:hover {
          transform: scale(1.1);
        }
      }

      ._bb {
        flex: 1;
        margin-left: 30px;

        ._sub {
          height: 75px;
          line-height: 25px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          /* 设置最大显示行数 */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }

      h4 {
        font-size: 20px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        font-size: 16px;
        color: #666;
        margin-top: 10px;
      }

      .xq {
        color: #a88b61;
      }
    }

    ._li:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>