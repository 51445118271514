import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Index from '../views/index'
import insPro from '../views/inspro/insPro'
import Notice from '../views/other/notice'
import Policy from '../views/other/policy'
import newsInfo from '../views/news/newsInfo'
import newsDetail from '../views/news/newsDetail'
import security from '../views/security/security'
import securityDetail from '../views/security/securityDetail'
import research from '../views/research/research'
import achieInfo from '../views/research/achieInfo'
import protect from '../views/research/protect'
import protectDetail from '../views/research/protectDetail'
import art from '../views/research/art'
import lmarch from '../views/research/lmarch'
import diglm from '../views/research/diglm'
import videoInfo from '../views/research/videoInfo'
import lmarchDetail from '../views/research/lmarchDetail'
import exchange from '../views/exchange/exchange'
import exchangeInfo from '../views/exchange/exchangeInfo'
import exchangeDetail from '../views/exchange/exchangeDetail'
import exhibitInfo from '../views/exchange/exhibitInfo'
import exhibitDetail from '../views/exchange/exhibitDetail'
import concealInfo from '../views/exchange/concealInfo'
import concealDetail from '../views/exchange/concealDetail'
import cultural from '../views/cultural/cultural'
import enterLmInfo from '../views/cultural/enterLmInfo'
import enterLmDetail from '../views/cultural/enterLmDetail'
import studyInfo from '../views/cultural/studyInfo'
import studyDetail from '../views/cultural/studyDetail'
import industriesInfo from '../views/cultural/industriesInfo'
import industriesDetail from '../views/cultural/industriesDetail'
import committee from '../views/committee/committee'
import sksInfo from '../views/committee/sksInfo'
import meetInfo from '../views/committee/meetInfo'
import solictiDetail from '../views/committee/solictiDetail'
import meetDetail from '../views/committee/meetDetail'
import searchInfo from '../views/search/searchInfo'
import searchDetail from '../views/search/searchDetail'
import webMap from '../views/other/webMap'
import drawInfo from '../views/research/drawInfo'
import numDetail from '../views/research/numDetail'
const routes = [

  {
    path: "/",
    name: "index",
    component: Index
  },
  {
    path: "/insPro",
    name: "insPro",
    component: insPro

  },
  {
    path: "/notice",
    name: "notice",
    component: Notice

  },
  {
    path: "/policy",
    name: "policy",
    component: Policy

  },
  {
    path: "/newsInfo",
    name: "newsInfo",
    component: newsInfo

  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: newsDetail

  },
  {
    path: "/security",
    name: "security",
    component:security

  },
  {
    path: "/securityDetail",
    name: "securityDetail",
    component: securityDetail

  },
  {
    path: "/research",
    name: "research",
    component:research

  },
  {
    path: "/achieInfo",
    name: "achieInfo",
    component: achieInfo

  },
  {
    path: "/protect",
    name: "protect",
    component: protect

  },
  {
    path: "/protectDetail",
    name: "protectDetail",
    component: protectDetail

  },
  {
    path: "/art",
    name: "art",
    component: art

  },
  {
    path: "/lmarch",
    name: "lmarch",
    component: lmarch

  },
  {
    path: "/diglm",
    name: "diglm",
    component: diglm

  },
  {
    path: "/videoInfo",
    name: "videoInfo",
    component: videoInfo

  },
  {
    path: "/lmarchDetail",
    name: "lmarchDetail",
    component: lmarchDetail

  },
  {
    path: "/exchange",
    name: "exchange",
    component:exchange

  },
  {
    path: "/exchangeInfo",
    name: "exchangeInfo",
    component: exchangeInfo

  },
  {
    path: "/exchangeDetail",
    name: "exchangeDetail",
    component: exchangeDetail

  },
  {
    path: "/exhibitInfo",
    name: "exhibitInfo",
    component:exhibitInfo

  },
  {
    path: "/exhibitDetail",
    name: "exhibitDetail",
    component: exhibitDetail

  },
  {
    path: "/concealInfo",
    name: "concealInfo",
    component:concealInfo

  },
  {
    path: "/concealDetail",
    name: "concealDetail",
    component: concealDetail

  },
  {
    path: "/cultural",
    name: "cultural",
    component: cultural

  },
  {
    path: "/enterLmInfo",
    name: "enterLmInfo",
    component: enterLmInfo

  },
  {
    path: "/enterLmDetail",
    name: "enterLmDetail",
    component: enterLmDetail
  },
  {
    path: "/studyInfo",
    name: "studyInfo",
    component: studyInfo

  },
  {
    path: "/studyDetail",
    name: "studyDetail",
    component: studyDetail

  },
  {
    path: "/industriesInfo",
    name: "industriesInfo",
    component: industriesInfo

  },
  {
    path: "/industriesDetail",
    name: "industriesDetail",
    component: industriesDetail

  },
  {
    path: "/committee",
    name: "committee",
    component: committee

  },
  {
    path: "/sksInfo",
    name: "sksInfo",
    component:sksInfo

  },
  {
    path: "/meetInfo",
    name: "meetInfo",
    component:meetInfo
  },
  {
    path: "/solictiDetail",
    name: "solictiDetail",
    component:solictiDetail
  },
  {
    path: "/meetDetail",
    name: "meetDetail",
    component: meetDetail

  },
  {
    path: "/searchInfo",
    name: "searchInfo",
    component: searchInfo

  },
  {
    path: "/searchDetail",
    name: "searchDetail",
    component:searchDetail

  },
  {
    path: "/webMap",
    name: "webMap",
    component:webMap

  },
  {
    path: "/drawInfo",
    name: "drawInfo",
    component:drawInfo

  },
  {
    path: "/numDetail",
    name: "numDetail",
    component: numDetail

  },
];




const router = new VueRouter({
  // mode: "history",
  mode: "hash",

  routes,
});



// router.beforeEach((to, from, next) => {
//   const path = to.path;
//   // 如果路径后面多了反斜杠，进行调整
//   console.log(path)
//   if (path.endsWith('/') && path != '/') {
//     const newPath = path.slice(0, -1);
//     // window.location.replace(newPath);
//     next({ path: newPath })
//     console.log(newPath)
//   }

//   next();
// });












// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}





export default router;
