<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread
        name="保护研究"
        path="/research"
        child="手绘龙门"
        childPath="/drawInfo"
      ></myBread>

      <div class="_js">
        <h4>手绘龙门</h4>
        <p>
          为进一步创新和完善龙门石窟艺术展示和传播形式，2023年开始，在研究院官网推出了《手绘龙门》栏目，在龙门石窟视频号上推出了《手绘龙门》动画视频。目前共推出八期约百余幅作品，作者均为龙门石窟研究院工作人员。作品推出后，全新的展现形式受到广大网友的好评。
        </p>
        <p>
          以龙门石窟造像艺术为主题的绘画创作，即能展现和传承龙门石窟不同时期石刻艺术的审美追求，丰富石窟艺术传播展示的方式；又能对石窟精美的石刻造像进行再解读，融入当代人对美的理解与思考，发挥绘画艺术无限的表现力
          ，赋予龙门石窟艺术新的生命力和感染力，从绘画的视角给观者带来更多的石窟艺术和美的享受。
        </p>
      </div>

      <div class="_yxList">
        <div
          class="_li"
          v-for="(item, index) in list"
          :key="index"
          @click="goDetail(item)"
        >
          <div class="_img">
            <img :src="$IMG_URL + item.cover" alt="" />
          </div>
          <div class="_sub">
            <h4>{{ item.title }}</h4>
            <p>{{ item.pub_time }}</p>
          </div>
        </div>
      </div>


      <div class="_page">
                        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page"
                            :limit.sync="queryParams.pagesize" @pagination="getList" />

                    </div>
    </div>


    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle, addHit } from "@/api/getData";
export default {
  data() {
    return {
        total:0,
        queryParams:{
            cate_id:"73",
            page:1,
            pagesize:9,
        },
      list: [
      ],
    };
  },

          // 动态设置页面标题、关键字和描述等属性  
          metaInfo() {

return {
  title: `手绘龙门_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  async mounted(){
    await this.getList()

  },

  methods: {
    goDetail(item) {
      const params = {
        id: item.id,
      };
      if (item.link != "" && item.link != null) {
      addHit(params);

        window.open(item.link);
      } else {
        this.$message.warning("暂无此系列内容");
      }
    },

    async getList(){
        await getArticle(this.queryParams).then(res=>{

            this.list = res.data.data

            this.total = res.data.total
        })
    }
  },
};
</script>

<style lang="scss" scoped>
._js {
  background-image: url("../../assets/image/research-img/image 616.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 30px 40px 20px;
  margin: 20px;

  h4 {
    font-size: 24px;
    color: #bb945b;
    font-weight: bold;
    margin-bottom: 17px;
  }
  p {
    color: #666;
    margin-bottom: 20px;
    line-height: 26px;
    text-indent: 2em;
  }
}

._yxList {
  margin: 0 20px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  ._li {
    cursor: pointer;
    margin-right: 16px;
    margin-bottom: 20px;
    ._img {
      width: 400px;
      height: 245px;
      overflow: hidden;
      border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.5s;
      }

      img:hover {
        transform: scale(1.1);
      }
    }

    ._sub {
      border-left: 2px solid #a88b61;
      padding-left: 10px;
      margin-top: 10px;

      h4 {
        font-size: 20px;
        color: #333;
        margin-bottom: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        color: #999999;
      }
    }
  }

  ._li:nth-child(3n) {
    margin-right: 0;
  }
}
</style>