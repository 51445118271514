<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="搜索结果" :path="'/searchInfo?key='+key"></myBread>

      <div class="_content">
        <h4>{{ detail.title }}</h4>
        <p>时间：{{ detail.pub_time }}</p>
        <div class="_html" ref="myHtml" v-html="detail.content"></div>
      </div>
    </div>

    <otherBwg />
  </div>
</template>

<script>
import { getDetail } from "@/api/getData";
export default {
  data() {
    return {
      id: "",
      key:"",
      tit: "",
      detail: {},
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `${this.detail.title}_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  async mounted() {
    let id = this.$route.query.id;
    let key = this.$route.query.key;
    this.id = id;
    this.key = key

    await this.getDetail();

        setTimeout(() => {
      this.$scallHtmlImg();
    }, 50);;
  },
  methods: {
    async getDetail() {
      const params = {
        id: this.id,
      };
      await getDetail(params).then((res) => {
        this.detail = res.data;
      });
    },

  },
};
</script>

<style lang="scss" scoped>
._content {
  margin-top: 40px;
  padding: 0 60px;

  > h4 {
    font-size: 26px;
    text-align: center;
  }

  > p {
    color: #999;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  ._html {
    min-height: 500px;
  }
}

::v-deep ._html p {
  line-height: 36px;
}

._shar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 50px;

  ._prev {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 10px;
      font-size: 18px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  ._wx {
    display: flex;
    align-items: center;

    span {
      color: #444;
    }

    img {
      width: 15px;
      height: 15px;
      margin-left: 10px;
    }
  }

  ._next {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-right: 10px;
      font-size: 18px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>