<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="保护研究" path='/research'></myBread>

      <div class="_noList">
        <div class="_lf">
          <div class="_top">
            <div class="_tabs1">
              <ul>
                <li>学术成果</li>
              </ul>
            </div>
            <div class="_more" @click="goCg">MORE</div>
          </div>

          <div class="_list">
            <div class="_li" v-for="(item, index) in sxcgList" :key="index">
              <h4>
                {{ item.title }}
              </h4>

              <p>
                <span
                  >{{ item.types }} / {{ item.author_name }} /
                  {{ item.publish_time }}年出版 / {{ item.open_book }}</span
                >
              </p>
            </div>
          </div>
        </div>

        <div class="_rt">
          <div class="_top">
            <h4>石窟保护</h4>
            <div class="_more" @click="goBh">MORE</div>
          </div>

          <div class="_rtList">
            <div
              class="_rtLi"
              v-for="(item, index) in skbhList"
              :key="index"
              @click="goBh(item, index)"
            >
              <img :src="item.img" alt="" />
              <span class="_shu">|</span>
              <span class="_name">{{ item.name }}</span>
            </div>
          </div>

          <div
            class="_top"
            style="margin-top: 10px; margin-bottom: 20px"
            id="skys"
          >
            <h4>石窟艺术</h4>
          </div>


          <div class="_shlm" @click="goSh">
            <img src="../../assets/image/research-img/shlm.png" alt="">
          </div>

          <div class="_ysList">
            <div
              class="_li"
              v-for="(item, index) in skysList"
              :key="index"
              @click="goYs(item)"
              style="cursor: pointer"
            >
              <div class="_img">
                <img :src="item.img" alt="" />
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>





        </div>
      </div>

      <div class="_noList">
        <div class="_lf">
          <div class="_top">
            <div class="_tabs1">
              <ul>
                <li>石窟考古</li>
              </ul>
            </div>
            <div class="_more" @click="goKg">MORE</div>
          </div>

          <div class="_list _lmkg">
            <div
              class="_li"
              v-for="(item, index) in lmkgList"
              :key="index"
              @click="goKgDetail(item)"
              style="cursor: pointer;"
            >
              <div class="_l_img">
                <img :src="$IMG_URL + item.cover" alt="" />
              </div>

              <div class="_r_text">
                <h4>
                  {{ item.title }}
                </h4>

                <p>
                  <span>{{ item.descs }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="_rt">
          <div class="_top">
            <h4>数字龙门</h4>
          </div>

          <div class="_szImg" @click="goLm">
            <img src="../../assets/image/research-img/szlm.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle, addHit } from "@/api/getData";
export default {
  data() {
    return {
      sxcgList: [],
      lmkgList: [],
      skbhList: [
        {
          img: require("../../assets/image/research-img/bhgc.png"),
          name: "保护工程",
          url: "",
        },
        {
          img: require("../../assets/image/research-img/bhxm.png"),
          name: "保护项目",
          url: "",
        },
        {
          img: require("../../assets/image/research-img/rcwh.png"),
          name: "日常维护",
          url: "",
        },
        {
          img: require("../../assets/image/research-img/skjc.png"),
          name: "石窟监测",
          url: "",
        },
      ],
      skysList: [
        {
          img: require("../../assets/image/research-img/Group 1179.png"),
          name: "装饰艺术",
        },
        {
          img: require("../../assets/image/research-img/Group 1180.png"),
          name: "雕刻艺术",
        },
        {
          img: require("../../assets/image/research-img/Group 1181.png"),
          name: "书法艺术",
        },
      ],
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `保护研究_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  mounted() {
    this.getData();

    let tit = this.$route.query.tit;

    if (tit == "石窟艺术") {
      this.$scrollToSection("skys");
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    }

 
  },
  methods: {
    goCg() {
      let url = location.origin;
      window.open(`${url}/#/achieInfo`);
    },
    goBh(item, index) {
      let url = location.origin;

      if (item.name) {
        window.open(`${url}/#/protect?current=${index}`);
      } else {
        window.open(`${url}/#/protect`);
      }
    },
    goYs(item) {
      let url = location.origin;
      const params = {
        id: item.id,
      };
      addHit(params);
      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/art?art=${item.name}`);
      }
    },
    goKg() {
      let url = location.origin;
      window.open(`${url}/#/lmarch`);
    },
    goKgDetail(item) {
      let url = location.origin;
      const params = {
        id: item.id,
      };
      addHit(params);
      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/lmarchDetail?id=${item.id}&tit=石窟考古`);
      }
    },
    goLm() {
      let url = location.origin;
      window.open(`${url}/#/diglm`);
    },

    goSh(){
      let url = location.origin;
      window.open(`${url}/#/drawInfo`);
    },

    getData() {
      const params = {
        cate_id: "29", //学术成果
        page: 1,
        pagesize: 7,
      };
      getArticle(params).then((res) => {
        this.sxcgList = res.data.data;
      });

      const params1 = {
        cate_id: "36", //石窟考古
        page: 1,
        pagesize: 3,
      };
      getArticle(params1).then((res) => {
        this.lmkgList = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
._auto {
  padding-bottom: 60px;
}

._more {
  border: 1px solid #bb945b;
  color: #303030;
  font-size: 14px;
  padding: 8px 14px;
  cursor: pointer;
}

._noList {
  display: flex;
  margin: 30px 20px 0;

  ._lf {
    width: 59.7%;

    ._top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f9f9f9;
      border-top: 1px solid #a88b61;

      ._tabs1 {
        ul {
          display: flex;

          li {
            color: #333;
            font-size: 22px;
            padding: 14px 30px;
            cursor: pointer;
            background-color: #a88b61;
            color: white;
          }
        }
      }

      ._more {
        margin-right: 20px;
      }
    }

    ._list {
      margin-top: 10px;

      ._li {
        border-bottom: 1px dashed #ebebeb;
        padding: 18px 0;

        h4 {
          color: #333;
          font-size: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        p {
          color: #999999;
          font-size: 18px;
          margin-top: 6px;
          text-indent: 1em;
        }
      }
    }

    ._lmkg {
      ._li {
        display: flex;

        ._l_img {
          width: 148px;
          height: 83px;
          overflow: hidden;
          border-radius: 5px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.5s;
          }

          img:hover {
            transform: scale(1.1);
          }
        }

        ._r_text {
          flex: 1;
          margin-left: 17px;
          width: 50%;
          p {
            text-indent: 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* 设置最大显示行数 */
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            color: #888;
            line-height: 26px;
            font-size: 16px;
          }
        }
      }
    }
  }

  ._rt {
    width: 38%;
    margin-left: 28px;

    ._top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f4f4f4;

      h4 {
        border-radius: 4px 4px 0 0;
        background: #f8f5ea;
        color: #bb945b;
        border-bottom: 1px solid #a88b61;
        padding: 17px 24px;
        font-size: 20px;
      }
    }

    ._rtList {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      ._rtLi {
        width: 225px;
        height: 48px;
        background: #f6f6f6;
        border: 1px solid #dfdfdf;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        img {
          margin-left: 20px;
        }

        ._shu {
          margin: 0 20px;
          color: #dfdfdf;
        }
      }
    }

    ._szImg {
      margin-top: 20px;
      cursor: pointer;

    }

    ._shlm{
      width: 100%;
      height: 200px;
      overflow: hidden;
      cursor: pointer;
      margin-bottom: 20px;
      img{
        width: 100%;
        height: 100%;
        transition: all .5s;
      }
      img:hover{
        transform: scale(1.1);
      }
    }

    ._ysList {
      display: flex;
      justify-content: space-between;

      ._li {
        position: relative;

        ._img {
          border-radius: 5px;
          overflow: hidden;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: all .5s;
          }

          img:hover{
            transform: scale(1.1);
          }
        }

        p {
          font-size: 18px;
          color: white;
          position: absolute;
          bottom: 10px;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
</style>