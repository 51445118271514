<template>
  <div>

    <navMenu></navMenu>


    <router-view></router-view>

    <mainFooter></mainFooter>


  </div>
</template>

<script>
import navMenu from '../components/navMenu/navMenu.vue';
export default {
  components: {
    navMenu
  },

};
</script>

<style></style>
