<template>
  <div class="_wrap">
    <div class="_auto">
      <!-- <myBread name="研学" path="/studyInfo" :child="detail.title" childPath='/studyDetail'></myBread> -->
      <myBread name="文旅融合" path='/cultural' child="研学" childPath='/studyInfo' :tChild='detail.title' tChildPath='/studyDetail'></myBread>


      <div class="_content">
        <h4 class="_title">{{ detail.title }}</h4>
        <p class="_time">时间：{{ detail.pub_time }}</p>

        <div v-if="detail.tuji != ''">
          <div class="_div" style="position: relative">
            <div
              style="
                --swiper-navigation-color: #fff;
                --swiper-pagination-color: #fff;
              "
              class="swiper mySwiper2"
            >
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in tujiList"
                  :key="index"
                >
                  <img :src="$IMG_URL + item" />
                </div>
              </div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
          <div thumbsSlider="" class="swiper mySwiper">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in tujiList"
                :key="index"
              >
                <img :src="$IMG_URL + item" />
              </div>
            </div>
          </div>
        </div>

        <div class="_html" ref="myHtml" v-html="detail.content"></div>
      </div>
      <div class="_shar">
        <div>
          <div class="_prev" v-if="detail.prev != '没有了'" @click="prev">
            <img src="../../assets/image/news-img/s.png" alt="" />

            <span>上一条</span>
          </div>
        </div>

        <div class="_wx">
          <span>分享到:</span>
          <!-- <img src="../../assets/image/news-img/wx.png" alt="" /> -->

          <el-popover
            placement="top"
            width="180"
            trigger="hover"
            style="cursor: pointer"
          >
            <div class="_code">
              <div id="qrcode" ref="qrcode"></div>
            </div>
            <img slot="reference" src="../../assets/image/news-img/wx.png" alt="" />
          </el-popover>

          <img
            @click="share('wb')"
            src="../../assets/image/news-img/wb.png"
            alt=""
          />
          <img
            @click="share('qq')"
            src="../../assets/image/news-img/qq.png"
            alt=""
          />
        </div>
        <div>
          <div class="_next" v-if="detail.next != '没有了'" @click="next">
            <span>下一条</span>
            <img src="../../assets/image/news-img/x.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getDetail } from "@/api/getData";
import Swiper from "swiper";
import QRCode from "qrcodejs2";

export default {
  data() {
    return {
      id: "",
      detail: {},
      tujiList: [],
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `${this.detail.title}_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  async mounted() {
    this.id = this.$route.query.id;
    await this.getDetail();

    this.initSwiper();
    this.creatQrCode()
    setTimeout(() => {
      this.$scallHtmlImg();
    }, 50);
    

  },
  // 监听,当路由发生变化的时候执行
  watch: {
    async $route(to, from) {
      console.log(to);
      let id = to.query.id;
      this.id = id;
      await this.getDetail();
      this.initSwiper();
      setTimeout(() => {
        this.$scallHtmlImg();
      }, 50);
      

    },
  },
  methods: {
    initSwiper() {
      var swiper = new Swiper(".mySwiper", {
        spaceBetween: 10,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesProgress: true,
      });
      var swiper2 = new Swiper(".mySwiper2", {
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        thumbs: {
          swiper: swiper,
        },
      });
    },

    async getDetail() {
      const params = {
        id: this.id,
      };
      await getDetail(params).then((res) => {
        this.detail = res.data;
        this.tujiList = this.detail.tuji.split(",");
      });
    },

    next() {
      let url = location.origin;
      window.location.href = `${url}/#/studyDetail?id=${this.detail.next.id}`;
    },
    prev() {
      let url = location.origin;
      window.location.href = `${url}/#/studyDetail?id=${this.detail.prev.id}`;
    },

    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrcode, {
        text: location.href, // 需要转换为二维码的内容
        width: 150, // 生成二维码得宽度
        height: 150, // 生成二维码得高度
        colorDark: "#000", // 二维码实点的颜色
        colorLight: "#fff", // 二维码背景色
        correctLevel: QRCode.CorrectLevel.H, // （1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      });
    },
    share(type) {
      if (type == "wb") {
        this.shareWb(
          location.href,
          "龙门石窟研究院",
          this.$IMG_URL + this.detail.cover
        );
      } else {
      }
      this.shareQQ(
        location.href,
        "龙门石窟研究院",
        this.detail.descs,
        this.$IMG_URL + this.detail.cover
      );
    },
  },
};
</script>

<style lang="scss" scoped>
._content {
  padding-bottom: 80px;

  ._title {
    font-size: 26px;
    color: #333333;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  ._time {
    text-align: center;
    color: #999;
    margin-bottom: 40px;
  }

  ._html {
    // margin-top: 40px;
    padding: 0 60px;
  }
}

::v-deep ._html p {
  line-height: 36px;
}

._shar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 50px;

  ._prev {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 10px;
      font-size: 18px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  ._wx {
    display: flex;
    align-items: center;

    span {
      color: #444;
    }

    img {
      width: 15px;
      height: 15px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  ._next {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-right: 10px;
      font-size: 18px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 72%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 518px;
  width: 72%;
  overflow: hidden;
  position: relative;
}

.mySwiper {
  height: 200px;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  // height: 100%;
  opacity: 0.4;
  height: 100px;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
  border: 3px solid #ba9258;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev::after {
  content: "";
}

.swiper-button-prev {
  top: 111%;
  left: 120px;
  width: 32px;
  height: 32px;
  background-image: url("../../assets/image/research-img/l.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.swiper-button-next {
  top: 111%;
  right: 120px;
  width: 32px;
  height: 32px;
  background-image: url("../../assets/image/research-img/r.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.swiper-button-next::after {
  content: "";
}

::v-deep ._html table{border:1px solid #555;font-family: 宋体, SimSun; font-size:15px; line-height:24px; text-align: center; border-collapse:collapse;}
::v-deep ._html table tr:first-child{font-weight:bold; text-align:center;}
::v-deep ._html table td{border:1px solid #555;font-family: 宋体, SimSun; font-size:15px; line-height:24px; text-align: center; padding: 10px; vertical-align: middle;}
::v-deep ._html table td img{
  margin-left: auto; 
  margin-right: auto;
}
</style>